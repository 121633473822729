import React, { useEffect, useState } from "react"
import Logo from "../../images/logo.png"
import Twitter from "../../images/social/Twitter.png"
import IG from "../../images/social/IG.png"
import Search from "../../images/search.png";
import { useNavigate } from 'react-router-dom'
import * as footerStyle from './footerStyle'
import { BASE_URL } from "../../helpers/wpHelper"
import axios from 'axios'

const Footer = ({ data }) => {
  const navigate = useNavigate()
  const [emailMessage, setEmailMessage] = useState("")
  const [email, setEmail] = useState("")
  const [search, setSearch] = useState("")

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE}`
    document.body.appendChild(script)
  })

  const handleEmail = e => {
    setEmail(e.target.value)
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute(`${process.env.REACT_APP_RECAPTCHA_SITE}`, { action: "submit" })
        .then(token => {
          let formData = new FormData()
          formData.set("email", email)
          formData.set("_wpcf7_recaptcha_response", token)

          axios.post(
            `${BASE_URL}wp-json/contact-form-7/v1/contact-forms/655/feedback`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data",
              },
            }
          )
          .then(res => {
            setEmailMessage("Thank you for subscribing!")
            setEmail("")
          })
        })
    })
  }

  const handleSearchSubmit = async e => {
    e.preventDefault();
    setSearch(search.replaceAll(" ", "+"));
    const response = await fetch(`${BASE_URL}wp-json/wp/v2/search/?search=${search}&_fields=id,subtype,title`);
    const data = await response.json();

    navigate("/search", {state: {results: data, search: search}});
    setSearch('')
  }

  return (
    <footerStyle.FooterContainer>
      <footerStyle.FooterWrapper>
        <footerStyle.FooterLogoWrapper>
          <footerStyle.FooterLogo src={Logo} />
        </footerStyle.FooterLogoWrapper>
        <footerStyle.FooterSectionsWrapper>
        <footerStyle.SectionWrapper>
          <footerStyle.Form id="emailForm" onSubmit={handleSubmit}>
            <footerStyle.FormLabel>Stay in touch.</footerStyle.FormLabel>
            <footerStyle.FormSubLabel>
              Enter your email below to recieve important updates and stay
              informed. You can unsubscribe at any time.
            </footerStyle.FormSubLabel>
            <footerStyle.FormWrapper>
              <footerStyle.FormInput
                type="email"
                placeholder="Email Address"
                name="EMAIL"
                id="mce-EMAIL"
                value={email}
                required
                onChange={handleEmail}
              />
              <footerStyle.FormButton>Subscribe</footerStyle.FormButton>
            </footerStyle.FormWrapper>
            <footerStyle.Message emailmessage={emailMessage}>
              {emailMessage}
            </footerStyle.Message>
          </footerStyle.Form>
          <footerStyle.Form id="searchForm" onSubmit={handleSearchSubmit}>
            <footerStyle.FormLabelSearch>Search</footerStyle.FormLabelSearch>
            <footerStyle.FormWrapper>
              <footerStyle.FormInput
                type="text"
                placeholder="Search..."
                name="SEARCH"
                id="search"
                value={search}
                required
                onChange={handleSearch}
              />
              <footerStyle.SearchButton>
                <footerStyle.SearchIcon src={Search} />
              </footerStyle.SearchButton>
            </footerStyle.FormWrapper>
          </footerStyle.Form>
        </footerStyle.SectionWrapper>
          <footerStyle.SectionWrapper>
            <footerStyle.SectionTitle>Pages</footerStyle.SectionTitle>
            <footerStyle.PageLink onClick={() => {navigate("/about")}}>About Us</footerStyle.PageLink>
            {/* <footerStyle.PageLink onClick={() => {navigate("/team")}}>Our Team</footerStyle.PageLink> */}
            <footerStyle.PageLink onClick={() => {navigate("/community")}}>Our Community</footerStyle.PageLink>
            <footerStyle.PageLink onClick={() => {navigate("/work")}}>Our Work</footerStyle.PageLink>
            <footerStyle.PageLink onClick={() => {navigate("/programs")}}>Our Programs</footerStyle.PageLink>
            {/* <footerStyle.PageLink onClick={() => {navigate("/events")}}>Events</footerStyle.PageLink> */}
            <footerStyle.PageLink onClick={() => {navigate("/news")}}>News & Updates</footerStyle.PageLink>
            {/* <footerStyle.PageLink onClick={() => {navigate("/media")}}>CILAR Media</footerStyle.PageLink> */}
            {/* <footerStyle.PageLink onClick={() => {navigate("/blog")}}>CILAR Blog</footerStyle.PageLink> */}
            {/* <footerStyle.PageLink onClick={() => {navigate("/impact")}}>Impact Dashboard</footerStyle.PageLink> */}
            <footerStyle.PageLink onClick={() => {navigate("/sponsorships")}}>Our Sponsorships</footerStyle.PageLink>
            {/* <footerStyle.PageLink onClick={() => {navigate("/career")}}>Careers</footerStyle.PageLink> */}
            <footerStyle.PageLink onClick={() => {navigate("/contact")}}>Contact Us</footerStyle.PageLink>
            <footerStyle.PageLink onClick={() => {navigate("/privacy-policy")}}>Privacy Policy</footerStyle.PageLink>
          </footerStyle.SectionWrapper>
          <footerStyle.SectionWrapper>
            <footerStyle.SectionTitle>Connect</footerStyle.SectionTitle>
            <footerStyle.SectionLogoWrapper>
              <footerStyle.LogoWrapperLink
                href="https://www.instagram.com/cilar_canada/?hl=en"
                target="_blank"
              >
                <footerStyle.SocialLogo src={IG} />
              </footerStyle.LogoWrapperLink>
              <footerStyle.LogoWrapperLink
                href="https://twitter.com/CILAR_CANADA"
                target="_blank"
              >
                <footerStyle.SocialLogo src={Twitter} />
              </footerStyle.LogoWrapperLink>
            </footerStyle.SectionLogoWrapper>
            <footerStyle.ExternalLink href="mailto:inquiries@cilar.ca">
              inquiries@cilar.ca
            </footerStyle.ExternalLink>
          </footerStyle.SectionWrapper>
        </footerStyle.FooterSectionsWrapper>
      </footerStyle.FooterWrapper>
      <footerStyle.FooterCopyright>
        <footerStyle.CopyrightText>
          Coalition of Innovation Leaders Against Racism © {new Date().getFullYear()}
        </footerStyle.CopyrightText>
        <footerStyle.CopyrightText>
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
          <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
        </footerStyle.CopyrightText>
      </footerStyle.FooterCopyright>
    </footerStyle.FooterContainer>
  )
}

export default Footer
