import React from "react"
import styled from "styled-components"
import { gql, useQuery } from '@apollo/client'

const TitleContainer = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: row;
  @media (max-width: 700px) {
    height: 100vh;
  }
`

const ComingSoonContainer = styled.div`
  height: 40vh;
  width: 100%;
  display: flex;
  background-color: #ffcb00;
  align-items: center;
  justify-content: center;
`

const ComingSoonText = styled.h5`
  align-self: center;
  justify-self: center;
  color: black;
  font-size: 20px;
  font-weight: 300;
  width: 50%;
  text-align: center;
  @media (max-width: 700px) {
    width: 80%;
    font-size: 18px;
  }

  @media (min-width: 1500px) {
    font-size: 50px;
  }

`

const Title = styled.h3`
  align-self: flex-end;
  font-weight: 600;
  color: black;
  line-height: 1.1;
  font-size: 90px;
  padding: 0 0 40px 60px;
  width: 60%;

  @media (max-width: 800px) {
    align-self: center;
    padding: 10px 20px 0 20px;
    font-size: 45px;
  }

  @media (max-width: 700px) {
    align-self: center;
    padding: 10px 20px 0 20px;
    font-size: 40px;
  }
  
  @media (min-width: 1500px) {
    padding: 0 0 40px 150px;
    font-size: 120px;
  }

  @media (min-width: 2000px) {
    padding: 0 0 40px 300px;
  }
`

const BackgroundArrowSmall = styled.img`
  position: absolute;
  z-index: -1;
  width: 20%;
  margin: 5% 0 0 20%;

  @media (max-width: 700px) {
    width: 50%;
    margin: 10% 0 0 20%;
  }

  @media (min-width: 1500px) {
    display: none;
  }
`

const BackgroundArrowLarge = styled.img`
  position: absolute;
  z-index: -1;
  width: 20%;
  right: 0;
  margin: 200px 200px 0 0;

  @media (max-width: 700px) {
    display: none;
  }

  @media (min-width: 1500px) {
    width: 30%;
  }

`

const GET_IMPACT = gql`
query GetImpact {
  allImpactSections {
    nodes {
      title
      categories(first: 10) {
        edges {
          node {
            name
          }
        }
      }
      impactDashboardHeading {
        title
        content
        asset {
          altText
          sourceUrl
        }
      }
    }
  }
}
`;

function ImpactPage () {
  const { loading, error, data } = useQuery(GET_IMPACT);
  return (
    <>
      {!loading ?
      <>
        {data.allImpactSections.nodes.map(section => {
          switch(section.categories.edges[0].node.name) {
            case ('Impact Dashboard Heading'):
              return <div key={section.impactDashboardHeading.title}>
                      <TitleContainer>
                        <BackgroundArrowSmall src={section.impactDashboardHeading.asset.sourceUrl}></BackgroundArrowSmall>
                        <Title dangerouslySetInnerHTML={{__html: section.impactDashboardHeading.title}}/>
                        <BackgroundArrowLarge src={section.impactDashboardHeading.asset.sourceUrl}></BackgroundArrowLarge>
                      </TitleContainer>
                      <ComingSoonContainer>
                        <ComingSoonText dangerouslySetInnerHTML={{__html: section.impactDashboardHeading.content}}/>
                      </ComingSoonContainer>
                    </div>
            default:
              return <></>
          }
        })}
      </>
      :
      <></>
      }
    </>
  )
}

export default ImpactPage
