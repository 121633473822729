import React, { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import Logo from "../../images/logo.png"
import gsap, { Power1 } from "gsap"
import OutlineArrow from "../../images/dna-arrow.png"
import {
  HeaderContainer,
  HeaderLogoButton,
  HeaderLogo,
  HeaderButtonContainer,
  HeaderButton,
  HeaderButtonText,
  NavigationContainer,
  NavigationBackArrow,
  NavigationBackground,
  NavigationWrapper,
  NavigationTextMask,
  NavigationLink,
  NavigationLinkText,
  NavigationNumber,
} from "./headerStyle"

const Header = () => {
  const navigate = useNavigate()
  const [navOpen, setNavOpen] = useState(false)
  const [navBG, setNavBG] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const changeBackground = () => {
    if (window.scrollY >= 60) {
      setNavBG(true)
    } else {
      setNavBG(false)
    }
  }

  const textSwipeUp = (elmt, pos) => {
    if (pos === 2) {
      gsap.to(`${elmt}`, {
        y: "0%",
        duration: 0.5,
        ease: "power1.out",
        onComplete: () => {
          setIsAnimating(false)
        },
      })
    } else {
      gsap.to(`${elmt}`, { y: "0%", duration: 0.25, ease: "power1.out" })
    }
  }

  const textSwipeDown = elmt => {
    gsap.to(`${elmt}`, { y: "100%", duration: 0.25, ease: "power1.in" })
  }

  const turnBGSmall = () => {
    gsap.to("#navBG", {
      scale: 1,
      autoAlpha: 0,
      duration: 0.25,
      ease: Power1.easeOut,
      onComplete: () => {
        setIsAnimating(false)
      },
    })
  }

  const animateArrows = action => {
    if (action === "show") {
      gsap.to(".navArrow", {
        autoAlpha: 0.2,
        duration: 0.2,
        ease: "power1.out",
      })
    } else {
      gsap.to(".navArrow", { autoAlpha: 0, duration: 0.2, ease: "power1.in" })
    }
  }

  const navMenuToggle = () => {
    if (!isAnimating) {
      if (!navOpen) {
        setIsAnimating(true)
        gsap.to("#navBG", {
          scale: 2.5,
          autoAlpha: 1,
          duration: 0.25,
          ease: Power1.easeIn,
        })
        gsap.delayedCall(0.25, animateArrows, ["show"])
        gsap.delayedCall(0.25, textSwipeUp, [".topRow", 1])
        gsap.delayedCall(0.27, textSwipeUp, [".middleRow1", 2])
        gsap.delayedCall(0.27, textSwipeUp, [".middleRow2", 3])
        gsap.delayedCall(0.27, textSwipeUp, [".bottomRow", 4])
        setNavOpen(true)
      } else {
        setIsAnimating(true)
        gsap.to(`.bottomRow`, { y: "100%", duration: 0.25, ease: "power1.in" })
        gsap.delayedCall(0.02, textSwipeDown, [".topRow"])
        gsap.delayedCall(0.02, textSwipeDown, [".middleRow1"])
        gsap.delayedCall(0.02, textSwipeDown, [".middleRow2"])
        gsap.delayedCall(0.05, animateArrows, ["hide"])
        gsap.delayedCall(0.3, turnBGSmall)
        setNavOpen(false)
      }
    }
  }

  useEffect(() => {
    changeBackground();
    window.addEventListener("scroll", changeBackground);
    if (navOpen) {
      document.body.style.top = `-${window.scrollY}px`
      document.body.style.position = "fixed"
    } else {
      const scrollY = document.body.style.top
      document.body.style.position = ""
      document.body.style.top = ""
      window.scrollTo(0, parseInt(scrollY || "0") * -1)
    }
  }, [navOpen])

  const arrowsFollowMouse = e => {
    if (navOpen) {
      let xCalc = e.clientX / window.innerWidth - 0.5
      let yCalc = e.clientY / window.innerHeight - 0.5
      gsap.to("#navArrow1", {
        x: `${xCalc * 100}%`,
        y: `${yCalc * 100}%`,
        duration: 2,
        ease: "power1.out",
      })
      gsap.to("#navArrow2", {
        x: `${xCalc * 70}%`,
        y: `${yCalc * 70}%`,
        duration: 3,
        ease: "power1.out",
      })
      gsap.to("#navArrow3", {
        x: `${xCalc * 40}%`,
        y: `${yCalc * 40}%`,
        duration: 4,
        ease: "power1.out",
      })
    }
  }

  const changePage = page => {
    navigate(`/${page}`)
    setTimeout(() => {
      document.body.style.top = "0px"
      navMenuToggle()
    }, 250)
  }

  const goHome = () => {
    if (navOpen) {
      navigate("/")
      setTimeout(() => {
        document.body.style.top = "0px"
        navMenuToggle()
      }, 250)
    } else {
      navigate("/")
    }
  }

  return (
    <>
      <HeaderContainer navbg={navBG ? "true" : "false"}>
        <HeaderLogoButton onClick={goHome}>
          <HeaderLogo src={Logo} />
        </HeaderLogoButton>
        <HeaderButtonContainer>
          <HeaderButtonText>Menu</HeaderButtonText>
          <HeaderButton onClick={navMenuToggle}></HeaderButton>
        </HeaderButtonContainer>
      </HeaderContainer>
      <NavigationContainer navopen={navOpen.toString()} onMouseMove={arrowsFollowMouse}>
        <NavigationWrapper>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("about")
              }}
              className="topRow"
            >
              <NavigationNumber>01</NavigationNumber>
              <NavigationLinkText>About Us</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("community")
              }}
              className="topRow"
            >
              <NavigationNumber>02</NavigationNumber>
              <NavigationLinkText>
                Our Community & Contributors
              </NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("work")
              }}
              className="topRow"
            >
              <NavigationNumber>03</NavigationNumber>
              <NavigationLinkText>Our Work</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("programs")
              }}
              className="topRow"
            >
              <NavigationNumber>04</NavigationNumber>
              <NavigationLinkText>Our Programs</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          {/* <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("impact")
              }}
              className="middleRow1"
            >
              <NavigationNumber>05</NavigationNumber>
              <NavigationLinkText>Impact Dashboard</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask> */}
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("sponsorships")
              }}
              className="middleRow1"
            >
			        <NavigationNumber>06</NavigationNumber>
			        <NavigationLinkText>Sponsors</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("contact")
              }}
              className="middleRow1"
            >
			        <NavigationNumber>07</NavigationNumber>
			        <NavigationLinkText>Contact Us</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("news")
              }}
              className="middleRow1"
            >
              <NavigationNumber>08</NavigationNumber>
              <NavigationLinkText>News & Updates</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          {/* <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("team")
              }}
              className="middleRow1"
            >
              <NavigationNumber>09</NavigationNumber>
              <NavigationLinkText>Our Team</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("career")
              }}
              className="middleRow2"
            >
              <NavigationNumber>10</NavigationNumber>
              <NavigationLinkText>Careers</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("events")
              }}
              className="middleRow2"
            >
              <NavigationNumber>11</NavigationNumber>
              <NavigationLinkText>Events</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("media")
              }}
              className="middleRow2"
            >
              <NavigationNumber>12</NavigationNumber>
              <NavigationLinkText>CILAR Media</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask>
          <NavigationTextMask>
            <NavigationLink
              onClick={() => {
                changePage("blog")
              }}
              className="middleRow2"
            >
              <NavigationNumber>13</NavigationNumber>
              <NavigationLinkText>CILAR Blog</NavigationLinkText>
            </NavigationLink>
          </NavigationTextMask> */}
		  {/*
		   */}
        </NavigationWrapper>
      </NavigationContainer>
      <NavigationBackArrow
        id="navArrow1"
        className="navArrow"
        src={OutlineArrow}
        size={40}
      />
      <NavigationBackArrow
        id="navArrow2"
        className="navArrow"
        src={OutlineArrow}
        size={30}
      />
      <NavigationBackArrow
        id="navArrow3"
        className="navArrow"
        src={OutlineArrow}
        size={20}
      />
      <NavigationBackground id="navBG"></NavigationBackground>
    </>
  )
}

export default Header
