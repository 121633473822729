import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import {
    ProgramContainer,
    BannerContainer,
    Title,
    OverviewWrapper
} from './programStyle'
import InnovatorsPage from "../../components/Innovators/innovators"
import { gql, useQuery } from '@apollo/client'
import ErrorSection from "../../components/Error/error";

const GET_PROGRAM = gql`
query GetProgram {
  pages {
    nodes {
      title
      parentDatabaseId
      program {
        slug
        title
        content
        preview {
          applicationInformation
          content
          funding {
            fundingInformation
            asset {
              altText
              sourceUrl
            }
          }
          partners {
            title
            altText
            sourceUrl
          }
        }
      }
      template {
        templateName
        ... on Template_Innovators {
          programInnovators {
            bottomContent
            innovator {
              name
              description
              asset {
                altText
                sourceUrl
              }
              socials {
                link
                socialMedia
              }
            }
          }
        }
      }
      programs {
        content
        title
        asset {
          altText
          sourceUrl
        }
      }
    }
  }
}
`;

function Program() {
  const programSlug = window.location.href.toString().split("/").slice(-1)[0]
  const { loading, error, data } = useQuery(GET_PROGRAM);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {!loading && !error ?
        <>
          {data.pages.nodes.map(page => {
            if (page.program.slug === programSlug) {
              return <ProgramContainer key={page.program.title}>
                      <BannerContainer>
                        <Title dangerouslySetInnerHTML={{__html: page.program.title}}/>
                      </BannerContainer>
                      <OverviewWrapper dangerouslySetInnerHTML={{__html: page.programs.content.replaceAll('<br />', '')}}/>
                      { page.template.templateName == 'Innovators' ?
                        <InnovatorsPage data={page.template}/>
                        :
                        <></>
                      }
                    </ProgramContainer>
            }
          })}
        </>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default Program