import styled from "styled-components"

export const ProgramContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`

export const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #333333;
  padding: 200px 60px 50px 60px;

  @media (max-width: 700px) {
    padding: 200px 20px 50px 20px;
  }

  @media (min-width: 1500px) {
    padding: 200px 150px 50px 150px;
  }

  @media (min-width: 2000px) {
    padding: 200px 300px 50px 300px;
  }
`

export const Title = styled.h1`
  width: 80%;
  color: white;
  font-size: 80px;
  text-align: left;
  line-height: 1.2;
  margin: 0 0 20px 0;

  @media (max-width: 900px) {
    font-size: 60px;
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 36px;
    width: 100%;
  }
`

export const OverviewWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
  margin-top: 40px;

  @media (max-width: 700px) {
    padding: 20px;
  }

  @media (min-width: 1500px) {
    padding: 80px 150px;
  }

  @media (min-width: 2000px) {
    padding: 100px 300px;
  }
`

export const ProgramText = styled.p`
  max-width: 1000px;
  margin-bottom: 20px;
`

export const ProgramTextBold = styled(ProgramText)`
  font-weight: 600;
`

export const PartnersWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 60px 60px 20px 60px;
  margin-top: 40px;
  background: #f6f6f6;

  @media (max-width: 700px) {
    padding: 40px 20px 10px 20px;
  }

  @media (min-width: 1500px) {
    padding: 80px 150px 40px 150px;
  }

  @media (min-width: 2000px) {
    padding: 100px 300px 50px 300px;
  }
`

export const PartnerRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: fit-content(40%) 1fr;
  grid-gap: 100px;
  padding-bottom: 60px;
  margin-top: 60px;
  border-bottom: 1px solid #9b9b9b;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`

export const PartnerLeftCol = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`

export const PartnerTitle = styled.p`

  color: black;
  text-decoration: none;
  font-weight: 600;
  margin: 24px 0 0 0;
  font-size: 16px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }
`

export const PartnerLink = styled.a`
  color: black;
  text-decoration: none;
  font-weight: 300;
  margin: 12px 0 0 0;
  font-size: 12px;

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 800px) {
    font-size: 12px;
  }
`

export const PartnerRightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ProfileHeading = styled.p`

  color: black;
  font-weight: 600;
  margin:0 0 16px 0;
  font-size: 24px;

  @media (max-width: 1200px) {
    font-size: 22px;
  }

  @media (max-width: 800px) {
    font-size: 24px;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  
  width: 150px;  

  @media (max-width: 550px) {
    width: 200px;
  }

`

export const ProfileImg = styled.img`
  width: 150px;

  @media (max-width: 500px) {
    width: 200px;
  }
`

export const PartnerSectionFacebookWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`