import React, { useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'
import { CilarContext } from "../../contexts/cilarContext"
import {
  FeaturedContainer,
  HeadingContainer,
  PageTitle,
  BlogDescription,
} from '../News/newsStyle'
import {
  Banner,
  BannerTextMask,
  BannerButton,
  SectionTitle,
  MediaContainer,
  MediaGrid,
  MediaLink,
  MediaCard,
  MediaPreviewImage,
  MediaPreviewText,
  Title,
  MediaContentPreview,
  MediaContentPreviewMobile,
  MediaQuote,
  MediaChevron
} from './mediaStyle'
import { gql, useQuery } from '@apollo/client'
import Logo from "../../images/large-logo.png"
import ErrorSection from "../../components/Error/error"

const GET_MEDIA = gql`
query GetMedia {
  pages(where: {title: "Media"}) {
    nodes {
      title
      mediaHeading {
        title
        content
        mediaRequest {
          content
          button {
            text
            link
          }
        }
        postHeading
      }
    }
  }
  allMediaPosts {
    nodes {
      title
      cilarMedia {
        title
        slug
        preview
        link
        asset {
          altText
          sourceUrl
        }
        quotes {
          quote
        }
        cilarArticle
      }
    }
  }
}
`;

function MediaPage() {
  const navigate = useNavigate();
  const { initialLandingAnimationCompleted, textSlideUp, textFadeIn } = useContext(CilarContext);
  const { loading, error, data } = useQuery(GET_MEDIA);

  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        textSlideUp("#mediaTitle")
        textSlideUp("#contactTitle")
        textFadeIn("#contactDesc")
      }, 2000)
    } else {
      setTimeout(() => {
        textSlideUp("#mediaTitle")
        textSlideUp("#contactTitle")
        textFadeIn("#contactDesc")
      }, 1000)
    }
  }, [])

  return (
    <>
      {!loading && !error ?
        <>
          <FeaturedContainer>
            <HeadingContainer>
              <PageTitle id="mediaTitle" dangerouslySetInnerHTML={{__html: data.pages.nodes[0].mediaHeading.title}}/>
              <BlogDescription dangerouslySetInnerHTML={{__html: data.pages.nodes[0].mediaHeading.content}}/>
            </HeadingContainer>
          </FeaturedContainer>
          <Banner>
            <BannerTextMask dangerouslySetInnerHTML={{__html: data.pages.nodes[0].mediaHeading.mediaRequest.content}}/>
            <BannerButton onClick={() => {navigate(`${data.pages.nodes[0].mediaHeading.mediaRequest.button.link}`)}}>{data.pages.nodes[0].mediaHeading.mediaRequest.button.text}</BannerButton>
          </Banner>
          <MediaContainer>
            {data.allMediaPosts.nodes && data.allMediaPosts.nodes.length > 0 ?
              <SectionTitle dangerouslySetInnerHTML={{__html: data.pages.nodes[0].mediaHeading.postHeading}}/>
              :
              <></>
            }
            <MediaGrid>
              {data.allMediaPosts.nodes.map(post => {
                return (
                    <MediaCard key={post.cilarMedia.title}>
                      <MediaPreviewImage src={post.cilarMedia.asset.sourceUrl} />
                      <MediaPreviewText>
                        <MediaLink onClick={() => {navigate(`/media/${post.cilarMedia.slug}`, {state: {articleTitle: post.title}})}}>
                          <Title>{post.cilarMedia.title}</Title>
                          <MediaContentPreview>
                            {post.cilarMedia.preview}
                          </MediaContentPreview>
                          <MediaContentPreviewMobile>
                            {post.cilarMedia.preview.substring(0, 100).trim() + "..."}
                          </MediaContentPreviewMobile>
                          {post.cilarMedia.quotes ?
                            <>
                              {post.cilarMedia.quotes.map((quote, i) => {
                                return <MediaQuote key={`${post.cilarMedia.title}-quote-${i}`} dangerouslySetInnerHTML={{__html: quote.quote}}/>
                              })}
                            </>
                            :
                            <></>
                          }
                        </MediaLink>
                        {post.cilarMedia.link ?
                          <BannerButton href={post.cilarMedia.link} target="_blank">Read Source → </BannerButton>
                          :
                          <></>
                        }
                        {post.cilarMedia.cilarArticle ?
                          <MediaChevron src={Logo} />
                          :
                          <></>
                        }
                      </MediaPreviewText>
                    </MediaCard>
                  // </MediaLink>
                )
              })}
            </MediaGrid>
          </MediaContainer>
        </>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default MediaPage
