import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import { gql, useQuery } from '@apollo/client'
import {
  PillarBannerContainer,
  PillarBannerImageWrapper,
  PillarBannerImage,
  PillarBannerGradient,
  PillarTitle,
  PillarBannerContentWrapper,
  BannerDescMask,
  BannerDesc,
  MobileBannerDesc,
  MobileBannerDescMask,
  PillarContainerLeft,
  PillarContainerRight,
  OverflowWrapper,
} from './workStyle'
import { TextMask } from "../../components/globalStyle"
import Pillar from '../../components/Pillar/Pillar'
import ErrorSection from "../../components/Error/error"

const GET_WORK = gql`
query GetWork {
  allWorkSections {
    nodes {
      title
      categories(first: 10) {
        edges {
          node {
            name
          }
        }
      }
      workHeader {
        title
        content
        asset{
          altText
          sourceUrl
        }
      }
      workArticle {
        title
        content
        layoutType
        assets {
          title
          altText
          sourceUrl
        }
      }
    }
  }
}
`;

function WorkPage() {
  const {
    initialLandingAnimationCompleted,
    textSlideUp,
  } = useContext(CilarContext)
  const { loading, error, data } = useQuery(GET_WORK);

  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        textSlideUp("#pillarsBannerTitle")
        textSlideUp("#pillarsBannerDesc")
        textSlideUp("#pillarsMobileBannerDesc")
      }, 2000)
    } else {
      setTimeout(() => {
        textSlideUp("#pillarsBannerTitle")
        textSlideUp("#pillarsBannerDesc")
        textSlideUp("#pillarsMobileBannerDesc")
      }, 1000)
    }
  }, [])

  return (
    <>
    {!loading && !error ?
        <OverflowWrapper>
          {data.allWorkSections.nodes.map(section => {
            switch(section.categories.edges[0].node.name) {
              case ('Work Heading'):
                return <PillarBannerContainer key={section.title}>
                          <PillarBannerImageWrapper>
                            <PillarBannerGradient>
                              <TextMask>
                                <PillarTitle id="pillarsBannerTitle" dangerouslySetInnerHTML={{__html: section.workHeader.title}}/>
                              </TextMask>
                              <MobileBannerDescMask>
                                <MobileBannerDesc id="pillarsMobileBannerDesc" dangerouslySetInnerHTML={{__html: section.workHeader.content}}/>
                              </MobileBannerDescMask>
                            </PillarBannerGradient>
                            <PillarBannerImage
                              src={section.workHeader.asset.sourceUrl}
                              alt={section.workHeader.asset.altText}
                            />
                          </PillarBannerImageWrapper>
                          <PillarBannerContentWrapper>
                            <BannerDescMask>
                              <BannerDesc id="pillarsBannerDesc" dangerouslySetInnerHTML={{__html: section.workHeader.content}}/>
                            </BannerDescMask>
                          </PillarBannerContentWrapper>
                        </PillarBannerContainer>
              case ('Work Article'):
                if (section.workArticle.layoutType === 'left') {
                  return <PillarContainerLeft key={section.title}>
                          <Pillar data={section.workArticle}/>
                        </PillarContainerLeft>
                } else if (section.workArticle.layoutType === 'right') {
                  return <PillarContainerRight key={section.title}>
                            <Pillar data={section.workArticle}/>
                          </PillarContainerRight>
                } else {
                  return <></>
                }
              default:
                return <></>
            }
          })}
        </OverflowWrapper>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default WorkPage
