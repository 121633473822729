import React, { createContext, useState } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export const CilarContext = createContext()

export function CilarProvider(props) {
  const [
    initialLandingAnimationCompleted,
    setInitialLandingAnimationCompleted,
  ] = useState(false)

  const [disableScroll, setDisableScroll] = useState(true)

  const completeLandingAnimation = () => {
    setInitialLandingAnimationCompleted(true)
  }

  const turnOnDisableScroll = () => {
    setDisableScroll(true)
  }

  const turnOffDisableScroll = () => {
    setDisableScroll(false)
  }

  const textSlideUp = elmt => {
    gsap.to(`${elmt}`, {
      scrollTrigger: {
        trigger: `${elmt}`,
        once: true,
        start: "top bottom-=50px",
        end: 1,
        scrub: 1,
      },
      y: 0,
      duration: 0.5,
      ease: "power1.out",
    })
  }

  const textFadeIn = elmt => {
    gsap.to(`${elmt}`, {
      scrollTrigger: {
        trigger: `${elmt}`,
        once: true,
        start: "top bottom-=50px",
        end: 1,
        scrub: 1,
      },
      autoAlpha: 1,
      opacity: 1,
      duration: 0.5,
      ease: "power1.out",
    })
  }

  return (
    <CilarContext.Provider
      value={{
        initialLandingAnimationCompleted,
        completeLandingAnimation,
        disableScroll,
        turnOnDisableScroll,
        turnOffDisableScroll,
        textSlideUp,
        textFadeIn,
      }}
    >
      {props.children}
    </CilarContext.Provider>
  )
}
