import styled from "styled-components"

export const SubheadingText = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media(max-width: 700px) {
    margin-left: 10px;
    flex-wrap: wrap;
  }
`

export const Author = styled.p`
  font-size: 14px;
`

export const Date = styled.p`
  font-size: 14px;
`