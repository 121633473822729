import { 
  Routes as Switch, 
  Route, 
  Navigate
} from 'react-router-dom';
import Homepage from './pages/Home/homepage';
import About from './pages/About/About';
import Community from './pages/Community/community';
import Impact from './pages/Impact/impact';
import Sponsorships from './pages/Sponsorships/sponsorships';
import Work from './pages/Work/work';
import Contact from './pages/Contact/contact';
import Programs from './pages/Programs/programs';
import Program from './pages/Program/program';
import ErrorPage from './pages/404/404';
import NewsPage from './pages/News/news';
import NewsArticle from './pages/NewsArticle/newsArticle';
import PrivacyPolicyPage from './pages/PrivacyPolicy/privacyPolicy';
import SearchPage from './pages/Search/search';
import TeamPage from './pages/Team/team';
import CareersPage from './pages/Careers/careers';
import EventsPage from './pages/Events/events';
import MediaPage from './pages/Media/media';
import MediaArticle from './pages/MediaArticle/mediaArticle';
import BlogPage from './pages/Blog/blog';
import BlogArticle from './pages/BlogArticle/blogArticle';

const Routes = function() {
  return (
    <Switch>
      <Route path="/" element={<Homepage/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/community" element={<Community/>}/>
      <Route path="/work" element={<Work/>}/>
      <Route path="/programs" element={<Programs/>}/>
      <Route path="/programs/:slug" element={<Program/>}/>
      {/* <Route path="/impact" element={<Impact/>}/> */}
      <Route path="/sponsorships" element={<Sponsorships/>}/>
      <Route path="/contact" element={<Contact/>}/>
      <Route path="/news" element={<NewsPage/>}/>
      <Route path="/news/:slug" element={<NewsArticle/>}/>
      <Route path="/media" element={<MediaPage/>}/>
      <Route path="/media/:slug" element={<MediaArticle/>}/>
      <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
      <Route path="/search" element={<SearchPage/>}/>
      <Route path="/team" element={<TeamPage/>}/>
      <Route path="/career" element={<CareersPage/>}/>
      <Route path="/events" element={<EventsPage/>}/>
      <Route path="/blog" element={<BlogPage/>}/>
      <Route path="/blog/:slug" element={<BlogArticle/>}/>
      <Route path="/404" element={<ErrorPage/>} />

      <Route path="*" element={<Navigate replace to="/404" />}/>
    </Switch>
  )
}

export default Routes;