import styled from "styled-components"
import {
  TextMask,
  SlideText,
} from "../../components/globalStyle"

const SideContainer = styled.div`
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-areas: "content image";

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-gap: 40px 0;
    margin-bottom: 80px;
    grid-template-areas:
      "image"
      "content";
  }
`

export const SecondSideContainer = styled(SideContainer)`
  margin: 150px 0;

  @media (max-width: 1000px) {
    margin: 50px 0;
  }
`

export const SideContentWrapper = styled.div`
  width: 100%;
  grid-area: content;
  padding: 0 100px 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 1200px) {
    padding: 0 80px 0 60px;
  }

  @media (max-width: 850px) {
    padding: 0 60px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 150px 0 300px;
  }
`

export const SideSmallTitleMask = styled(TextMask)`
  margin-bottom: 40px;
`

export const SideDescMask = styled(TextMask)`
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`

export const SideDesc = styled(SlideText)`
  width: 100%;
  position: relative;
`

export const ImageGrid = styled.div`
  width: calc(100% - 60px);
  grid-area: image;
  margin: 0 60px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: fit-content;

  @media (max-width: 1000px) {
    margin: 0;
    width: 100%;
  }

  @media (min-width: 1500px) {
    margin: 0 150px 0 0;
    width: calc(100% - 150px);
  }

  @media (min-width: 2000px) {
    margin: 0 300px 0 0;
    width: calc(100% - 300px);
  }
`

export const ImageCell = styled.div`
  width: 100%;
  position: relative;
`

export const ImageGradient = styled.div`
  position: absolute;
  z-index: 2;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`

export const ImageText = styled.span`
  font-size: 20px;
  margin: 0;
  padding: 25px 20px 20px 0;
  color: white;

  @media (max-width: 500px) {
    font-size: 16px;
  }
`

export const Img = styled.img`
  width: 100%;
  z-index: 1;
`