import styled from "styled-components"
import { TextMask, SlideSubTitle, SlideText } from "../globalStyle"

export const HelperTitle = styled(SlideSubTitle)`
  position: relative;
  z-index: 1;
`

export const HelperDesc = styled(SlideText)`
  position: relative;
`

export const ContactContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 150px 60px 110px 60px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  background-color: #333;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    padding: 100px 60px;
    grid-gap: 30px;
    justify-items: center;
  }

  @media (max-width: 700px) {
    padding: 80px 20px;
  }

  @media (min-width: 1500px) {
    padding: 150px 150px;
  }

  @media (min-width: 2000px) {
    padding: 150px 300px;
  }
`

export const ContactContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    max-width: 700px;
  }
`

export const ContactTitleMask = styled(TextMask)`
  margin: 0 0 40px 0;

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 700px;
    margin-bottom: 30px;
  }
`

export const ContactTitle = styled(HelperTitle)`
  color: #ffcb00;

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 700px;
  }
`

export const ContactDescMask = styled(TextMask)`
  margin: 0 0 60px 0;

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 700px;
    margin-bottom: 10px;
  }
`

export const ContactDesc = styled(HelperDesc)`
  color: white;
  max-width: 500px;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    max-width: 700px;
  }
`

export const ContactExtraWrapperMask = styled(TextMask)`
  @media (max-width: 1000px) {
    max-width: 700px;
    width: 100%;
    margin-top: 40px;
  }

  @media (max-width: 850px) {
    margin-top: 20px;
  }
`

export const ContactExtraWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  transform: translateY(100%);

  @media (max-width: 1000px) {
    display: none;
    max-width: 700px;
  }
`

export const ContactExtraWrapperMobileMask = styled(ContactExtraWrapperMask)`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
  }
`

export const ContactExtraWrapperMobile = styled(ContactExtraWrapper)`
  display: none;
  @media (max-width: 1000px) {
    display: flex;
  }
`

export const ContactSubTitle = styled.h6`
  font-size: 24px;
  margin-bottom: 10px;
  color: white;
  max-width: 500px;
  font-weight: 400;

  @media (max-width: 1200px) {
    font-size: 20px;
  }

  @media (max-width: 1000px) {
    max-width: 600px;
  }
`

export const ContactAddress = styled.p`
  margin-bottom: 8px;
  color: white;
  max-width: 500px;

  @media (max-width: 1000px) {
    max-width: 600px;
  }
`

export const ContactEmail = styled.a`
  font-size: 20px;
  margin: 0 0 30px 0;
  color: white;
  max-width: 500px;
  font-weight: 300;

  &:last-child {
    margin: 0;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 1000px) {
    max-width: 600px;
  }
`

export const ContactForm = styled.form`
  display: flex;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;

  @media (max-width: 1000px) {
    margin: 0;
  }

  @media (min-width: 2000px) {
    margin-top: 40px;
  }
`

export const ContactInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const ContactFormLabel = styled.label`
  font-size: 18px;
  font-weight: 300;
  color: white;
  margin: 0 0 6px 0;
  width: 100%;
  text-align: left;
  text-transform: uppercase;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`

export const ContactFormInput = styled.input`
  width: 100%;
  background: white;
  border: none;
  border-radius: 5px;
  height: 44px;
  margin-bottom: 20px;
  padding: 5px 15px;
  box-sizing: border-box;
  font-family: poppins;
  font-size: 18px;
  font-weight: 300;
  color: black;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    padding: 5px 10px;
  }
`

export const ContactFormWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`

export const ContactFormSelector = styled.select`
  width: 100%;
  background: white;
  border: none;
  border-radius: 5px;
  height: 44px;
  margin-bottom: 20px;
  padding: 5px 40px 5px 15px;
  box-sizing: border-box;
  font-family: poppins;
  font-size: 18px;
  font-weight: 300;
  color: black;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    padding-right: 27px;
    padding-left: 10px;
  }
`

export const ContactFormSelectorArrow = styled.img`
  width: 20px;
  position: absolute;
  right: 15px;
  bottom: 35px;
  z-index: 2;
  pointer-events: none;

  @media (max-width: 500px) {
    width: 12px;
    right: 10px;
    bottom: 37px;
  }
`

export const Option = styled.option`
  @media (max-width: 500px) {
    font-size: 14px;
  }
`

export const ContactFormInputLarge = styled.textarea`
  width: 100%;
  background: white;
  border: none;
  border-radius: 5px;
  height: 200px;
  padding: 10px 15px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-family: poppins;
  font-size: 18px;
  font-weight: 300;
  color: black;
  resize: none;

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    height: 150px;
    padding: 10px;
  }
`

export const ContactButton = styled.button`
  width: fit-content;
  padding: 0 30px;
  height: 45px;
  display: grid;
  place-items: center;
  background-color: #ffcb00;
  border: none;
  border-radius: 30px;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  margin-top: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 1200px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
  }
`

export const ContactMessage = styled.div`
  font-weight: 400;
  font-size: 12px;
  margin: 20px 0 0 0;
  color: white;
  opacity: ${props => (props.contactmessage === "empty" ? "0" : "1")};
`