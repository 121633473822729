import styled from "styled-components"
import {
  TextMask,
  SlideTitle,
  SlideSubTitle,
  SlideText,
} from "../../components/globalStyle"

export const PillarMultiImageContainer = styled.div`
  width: 100%;
  padding: 0 30px;
  display: grid;
  place-items: end;
  height: fit-content;
  opacity: 1;
  grid-area: image;
  margin-top: 40px;
  align-items: start;
  grid-row-gap: 20px;
  grid-column-gap: 60px;

  /* @media (min-width: 1200px) {
    grid-column-gap: 86px;
  } */

  @media (max-width: 860px) {
    align-self: center;
    width: 70%;
    padding: 0;
  }

  @media (max-width: 460px) {
    align-self: flex-start;
  }

  @media (max-width: 400px) {
    grid-column-gap: 13vw;
  }
  grid-template-columns: repeat(6, 1fr);

  div:nth-of-type(1) {
    grid-column: 1 / span 2;
  }

  div:nth-of-type(2) {
    grid-column: 3 / span 2;
  }

  div:nth-of-type(3) {
    grid-column: 5 / span 2;
  }

  div:nth-of-type(4) {
    grid-column: 2 / span 2;
  }

  div:nth-of-type(5) {
    grid-column: 4 / span 2;
  }
`

export const PillarCircleImageWrapper = styled.div`
  width: 105px;
  height: 105px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333333;
  border-radius: 50%;
  border: 3px #ffcc08 solid;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @media (min-width: 1200px) {
    width: 120px;
    height: 120px;
    /* width: 10vw;
    height: 10vw; */
  }

  @media (max-width: 1024px) {
    width: 90px;
    height: 90px;
    /* width: 10vw;
    height: 10vw; */
  }

  @media (max-width: 460px) {
    width: 50px;
    height: 50px;
  }
`
export const PillarCircleImage = styled.img`
  width: ${props => (props.width ? props.width : "78%")};
`

export const PillarSideContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  grid-area: content;
  ::before {
    display: none;
  }
  ::after {
    display: none;
  }
`

export const PillarSideTitle = styled(SlideSubTitle)`
  width: 100%;
  position: relative;
  color: #333333;
  line-height: 95%;
  @media (max-width: 400px) {
    font-size: 10vw;
  }
  ::before {
    display: none;
  }
  ::after {
    display: none;
  }
`

export const PillarSideTitleMask = styled(TextMask)`
  margin-bottom: 10px;
  overflow: visible;
`

export const PillarSideText = styled(SlideText)`
  width: 100%;
  position: relative;
  color: #333333;
`

export const PillarMultiImage3plus2 = styled(PillarMultiImageContainer)`
  @media (max-width: 860px) {
    width: 50%;
  }

  @media (max-width: 400px) {
    grid-column-gap: 10vw;
    padding-left: 20px;
  }
`

export const PillarMultiImage2plus3plus2 = styled(PillarMultiImageContainer)`
  grid-template-columns: repeat(6, 1fr);
  margin-top: 0;
  div:nth-of-type(1) {
    grid-column: 2 / span 2;
  }

  div:nth-of-type(2) {
    grid-column: 4 / span 2;
  }

  div:nth-of-type(3) {
    grid-column: 1 / span 2;
  }

  div:nth-of-type(4) {
    grid-column: 3 / span 2;
  }

  div:nth-of-type(5) {
    grid-column: 5 / span 2;
  }

  div:nth-of-type(6) {
    grid-column: 2 / span 2;
  }

  div:nth-of-type(7) {
    grid-column: 4 / span 2;
  }
  @media (max-width: 860px) {
    font-size: 10vw;
    grid-gap: 10px;
    grid-template-columns: repeat(8, 1fr);
    align-self: flex-start;
    width: 400px;
    div:nth-of-type(1) {
      grid-column: 1 / span 2;
    }

    div:nth-of-type(2) {
      grid-column: 3 / span 2;
    }

    div:nth-of-type(3) {
      grid-column: 5 / span 2;
    }

    div:nth-of-type(4) {
      grid-column: 7 / span 2;
    }

    div:nth-of-type(5) {
      grid-column: 2 / span 2;
    }

    div:nth-of-type(6) {
      grid-column: 4 / span 2;
    }

    div:nth-of-type(7) {
      grid-column: 6 / span 2;
    }
  }

  @media (max-width: 470px) {
    width: 80vw;
  }
`

export const PillarMultiImage2plus2 = styled(PillarMultiImageContainer)`
  grid-template-columns: 1fr 1fr;
  padding-right: 120px;
  align-self: center;
  justify-self: center;
  div:nth-of-type(1) {
    grid-column: auto;
  }

  div:nth-of-type(2) {
    grid-column: auto;
  }

  div:nth-of-type(3) {
    grid-column: auto;
  }

  div:nth-of-type(4) {
    grid-column: auto;
  }

  @media (max-width: 860px) {
    /* display: flex;
    justify-content: flex-end;
    align-self: flex-end; */
    display: flex;
    margin-right: 30px;
    width: 100%;
    padding-right: 0;
  }

  @media (max-width: 430px) {
    margin-right: 10px;
    grid-column-gap: 0;
    div {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
`

export const MiddleImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  margin-bottom: 30px;
  /* grid-gap: 30px; */
  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
  }
`

export const MiddleImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${props => `${props.paddingTop}px`};
  align-items: center;
  div {
    margin-top: 20px;
  }

  @media (max-width: 860px) {
    flex-direction: row;
    justify-content: center;
    padding-top: 0;
    div {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media (max-width: 350px) {
    div {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
`