import React from "react"
import {
    ProgramText,
    PartnersWrapper,
		PartnerRow,
		PartnerLeftCol,
		ProfileContainer,
		ProfileImg,
		PartnerTitle,
		PartnerLink,
		PartnerRightCol,
		ProfileHeading,
		PartnerSectionFacebookWrapper
} from './innovatorsStyle'

function InnovatorsPage ({ data }) {
  return (
    <>
      <PartnersWrapper>
        {data.programInnovators.innovator.map(i => {
          return <PartnerRow key={i.name}>
                  <PartnerLeftCol>
                    <ProfileContainer>
                      <ProfileImg src={i.asset.sourceUrl} alt={i.asset.altText} />
                    </ProfileContainer>
                    {i.socials ?
                      <>
                      <PartnerTitle>
                        Socials
                      </PartnerTitle>
                      {i.socials.map(s => {
                        return <PartnerLink href={s.link} target="_blank" key={`${s.socialMedia}-${i.name}`}>
                                {s.socialMedia}
                              </PartnerLink>
                      })}
                      </>
                      :
                      <></>
                    }
                  </PartnerLeftCol>
                  <PartnerRightCol>
                    <ProfileHeading dangerouslySetInnerHTML={{__html: i.name}}/>
                    <ProgramText dangerouslySetInnerHTML={{__html: i.description}}/>
                  </PartnerRightCol>
                </PartnerRow>
        })}
        <PartnerSectionFacebookWrapper>
          <ProgramText dangerouslySetInnerHTML={{__html: data.programInnovators.bottomContent}}/>
        </PartnerSectionFacebookWrapper>
      </PartnersWrapper>
    </>
  )
}

export default InnovatorsPage