import React from "react"
// import SEO from "../components/seo"
import styled from "styled-components"

const NotFoundContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 200px 60px;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    padding: 150px 20px;
  }

  @media (min-width: 1500px) {
    padding: 300px 150px;
  }

  @media (min-width: 2000px) {
    padding: 400px 300px;
  }
`

const NotFoundPage = () => (
  <>
    {/* <SEO title="404: Not found" /> */}
    <NotFoundContainer>
      <h1>404: No page exists at this URL</h1>
      <br></br>
      <p>Please select a page to navigate to from the menu or footer.</p>
    </NotFoundContainer>
  </>
)

export default NotFoundPage
