import React from "react"
import Timeline from '../../components/Timeline/timeline'
import { gql, useQuery } from '@apollo/client'
import Gallery from "../../components/Gallery/gallery";
import HeadingSplitLayout from "../../components/HeadingSplitLayout/headingSplitLayout";
import ErrorSection from "../../components/Error/error";

const GET_ABOUT = gql`
query GetAbout {
  allAboutSections {
    nodes {
      title
      categories(first: 10) {
        edges {
          node {
            name
          }
        }
      }
      aboutHeading {
        title
        content
        asset {
          altText
          sourceUrl
        }
      }
      galleryArticle {
        title
        content
        assets {
          name
          asset {
            altText
            sourceUrl
          }
        }
      }
      aboutTimeline {
        timelineBubble {
          content
          date
        }
      }
    }
  }
}
`;

function About() {
  const { loading, error, data } = useQuery(GET_ABOUT);

  return (
    <>
      {!loading && !error ?
      <>
        {data.allAboutSections.nodes.map(section => {
          switch(section.categories.edges[0].node.name) {
            case ('About Article'):
              return <HeadingSplitLayout key={section.title} data={section}/>
            case ('Gallery Article'):
              return <Gallery key={section.galleryArticle.title} data={section}/>
            case ('Timeline'):
              return <Timeline key={section.title} data={section.aboutTimeline}/>
            default:
              return <></>
          }
        })}
      </>
      :
      <>
        {error ?
          <ErrorSection/>
          :
          <></>
        }
      </>
      }
    </>
  )
}

export default About
