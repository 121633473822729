import styled from "styled-components"

export const Banner = styled.div`
  font-family: poppins;
  font-weight: 300;
  background-color: #ffcb00;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  p {
    max-width: 800px;
    text-align: center;
    transform: translateY(100%);
    margin: 5px 0;
    padding: 0 25px;
  }

  @media (max-width: 800px) {
    p {
      font-size: 14px;
    }
  }
`

export const BannerTextMask = styled.div`
  width: fit-content;
  height: fit-content;

  a {
    color: inherit;
    text-decoration: underline;
  }

  @media (max-width: 500px) {
    p {
      margin-bottom: 20px;
    }
  }
`

export const WhiteLogo = styled.img`
  width: 100px;
  margin: 60px 0 20px 0;
`

export const SponsorsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Sponsors = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 75px;
  padding: 100px 0;

  @media (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px 20px;
  }
`

export const SponsorBubble = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 100%;
  background-color: #333333;
  height: 100px;
  width: 100px;
  padding: 40px;
  margin: 0 30px 0 0;
  border: 1px solid #ffcb00;
  box-shadow: 5px 5px 20px;
  box-sizing: content-box;

  @media (max-width: 800px) {
    height: 75px;
    width: 75px;
    padding: 40px;
  }

  @media (max-width: 550px) {
    height: 60px;
    width: 60px;
    padding: 40px;
  }

  @media (max-width: 400px) {
    height: 40px;
    width: 40px;
    padding: 40px;
  }
`

export const SponsorImg = styled.img`
  width: 100px;
  object-fit: contain;
`