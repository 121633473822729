import styled from "styled-components"
import {
    TextMask,
    SlideSubTitle,
    SlideText,
  } from "../globalStyle"

export const SideContainer = styled.div`
  width: 100%;
  margin: 120px 0;
  display: grid;
  grid-template-columns: ${props =>
    props.imageside === "left" ? "55% 45%" : "55% 45%"};

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
    grid-gap: 80px 0;
    margin: 100px 0 80px 0;
  }
`

export const SideImage = styled.img`
  width: 100%;

  @media (max-width: 850px) {
    width: calc(100% - 120px);
    margin: 0 auto;
    height: 450px;
    object-fit: cover;
  }

  @media (max-width: 700px) {
    width: calc(100% - 40px);
    height: 400px;
  }
`

export const SideContentWrapper = styled.div`
  width: 100%;
  padding: ${props =>
    props.imageside === "left" ? "0 60px 0 100px" : "0 100px 0 60px"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 1200px) {
    padding: ${props =>
      props.imageside === "left" ? "0 60px 0 80px" : "0 80px 0 60px"};
  }

  @media (max-width: 850px) {
    padding: 0 60px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px 0 150px;
  }

  @media (min-width: 2000px) {
    padding: ${props =>
      props.imageside === "left" ? "0 300px 0 150px" : "0 150px 0 300px"};
  }
`

export const SideTitleMask = styled(TextMask)`
  margin-bottom: 40px;
`

export const SideTitle = styled(SlideSubTitle)`
  position: relative;
  z-index: 1;
  width: 100%;
`

export const SideLongTitle = styled(SideTitle)`
  @media (max-width: 500px) {
    font-size: 48px;
  }
`

export const SideDescMask = styled(TextMask)`
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`

export const SideDesc = styled(SlideText)`
  width: 100%;
  position: relative;
`

export const ProcessDesc = styled(SideDesc)`
  color: white;
  width: 500px;
  position: relative;

  @media (max-width: 1000px) {
    width: 400px;
  }

  @media (max-width: 850px) {
    width: 100%;
    max-width: 600px;
  }

  @media (min-width: 1500px) {
    width: 600px;
  }
`

export const TargetContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 60px 0;
  width: 100%;
  padding: 0 60px;

  @media (max-width: 850px) {
    margin: 40px 0;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (max-width: 500px) {
    margin: 20px 0;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 300px;
  }
`

export const TargetContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 850px) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 60px;
  }
`

export const TargetTitleMask = styled(TextMask)`
  @media (max-width: 850px) {
    margin-bottom: 30px;
  }
`

export const TargetTitle = styled(SideTitle)`
  position: relative;
  padding: 10px 0;
`

export const TargetDesc = styled(ProcessDesc)`
  position: relative;
  color: black;
`