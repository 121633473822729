import React from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { gql, useQuery } from '@apollo/client'

// Components
import Contact from "../../components/Contact/contact"
import TextRightSection from "../../components/Layout/TextRightSection"
import TextBottomSection from "../../components/Layout/TextBottomSection"
import TextLeftSection from "../../components/Layout/TextLeftSection"
import Landing from "../../components/LandingBanner/Landing"
import ErrorSection from "../../components/Error/error"

gsap.registerPlugin(ScrollTrigger)

const GET_HOME = gql`
  query GetHome {
    allHomepageSections {
      nodes {
        title
        categories(first: 10) {
          edges {
            node {
              name
            }
          }
        }
        article {
          title
          content
          layoutType
          asset {
            altText
            sourceUrl
          }
        }
        landingBanner {
          title
          content
          webAsset {
            altText
            sourceUrl
          }
          mobileAsset {
            altText
            sourceUrl
          }
        }
        contact {
          header {
            title
            content
          }
          contactDetails {
            title
            address
            email
          }
        }
      }
    }
  }
`;

function Homepage () {
  const { loading, error, data } = useQuery(GET_HOME);

  return (
    <>
      {!loading && !error ?
        <>
          {data.allHomepageSections.nodes.map(section => {
            switch(section.categories.edges[0].node.name) {
              case ('Homepage Heading'):
                return <Landing key={section.title} data={section.landingBanner}/>
              case ('Homepage Article'):
                // Different article layout options
                switch (section.article.layoutType) {
                  case ('text_right'):
                    return <TextRightSection key={section.title} data={section.article}/>
                  case ('text_left'):
                    return <TextLeftSection key={section.title} data={section.article}/>
                  case ('text_bottom'):
                    return <TextBottomSection key={section.title} data={section.article}/>
                  default:
                    return <></>
                }
              case ('Homepage Contact'):
                return <Contact key={section.title} data={section.contact} />
              default:
                return <></>
            }
          })}
        </>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default Homepage
