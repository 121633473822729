import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import {
  PillarCircleImage,
  PillarCircleImageWrapper,
  PillarSideContent,
  PillarSideTitle,
  PillarSideTitleMask,
  PillarSideText,
  PillarMultiImageContainer,
  PillarMultiImage2plus3plus2,
  PillarMultiImage2plus2,
} from './pillarStyle'
import {
  TextMask,
} from "../../components/globalStyle"

function Pillar({ data }) {
  const {
    textSlideUp,
    textFadeIn,
  } = useContext(CilarContext)

  useEffect(() => {
    textSlideUp("#pillarsSideTitleLeft")
    textSlideUp("#pillarsSideTextLeft")
    textFadeIn("#pillarsSideImageLeft")
    textSlideUp("#pillarsSideTitleRight")
    textSlideUp("#pillarsSideTextRight")
    textFadeIn("#pillarsSideImageRight")
  }, [])

  return (
    <>
      <PillarSideContent>
        <PillarSideTitleMask>
          <PillarSideTitle id="pillarsSideTitleLeft" dangerouslySetInnerHTML={{__html: data.title}}/>
        </PillarSideTitleMask>
        <TextMask>
          <PillarSideText id="pillarsSideTextLeft" dangerouslySetInnerHTML={{__html: data.content}}/>
        </TextMask>
      </PillarSideContent>
      {data.assets ?
        <>
          {data.assets.length > 5 &&
            <PillarMultiImage2plus3plus2>
              {data.assets.map(asset => {
                return <PillarCircleImageWrapper key={`2+3+2+${asset.title}`}>
                        <PillarCircleImage src={asset.sourceUrl} width={"66%"}/>
                      </PillarCircleImageWrapper>
              })}
            </PillarMultiImage2plus3plus2>
          }
          {data.assets.length == 5 &&
            <PillarMultiImageContainer>
              {data.assets.map(asset=> {
                return <PillarCircleImageWrapper key={asset.title}>
                        <PillarCircleImage src={asset.sourceUrl} width={"66%"}/>
                      </PillarCircleImageWrapper>
              })}
            </PillarMultiImageContainer>
          }
          {data.assets.length < 5 &&
            <PillarMultiImage2plus2 layout="2+2">
              {data.assets.map(asset => {
                return <PillarCircleImageWrapper key={`2+2+${asset.title}`}>
                        <PillarCircleImage src={asset.sourceUrl} width={"66%"}/>
                      </PillarCircleImageWrapper>
              })}
            </PillarMultiImage2plus2>
          }
        </>
        :
        <></>
      }
    </>
  )
}

export default Pillar