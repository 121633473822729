import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import {
  ProgramContainer,
  BannerContainer,
  Title,
  OverviewWrapper,
  BlogRecs,
  BlogRecTitle,
  BlogRecPreviewTitle,
  HeaderImage,
  HeaderImageContainer,
} from './newsArticleStyle'
import {
  BlogGrid,
  BlogCard,
  BlogPreviewImage,
  BlogContentPreview,
  BlogLink,
  BlogPreviewText,
  BlogContentPreviewMobile,
} from '../News/newsStyle'
import { useNavigate } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import ErrorSection from "../../components/Error/error";

const GET_ARTICLE = gql`
query GetArticle {
  posts (where: {categoryName: "News"}) {
    nodes {
      newsArticle {
        title
        slug
        content
        date
        author
        preview
        asset {
          altText
          sourceUrl
        }
        assetInArticleHeader
        recommendedPosts {
          post {
            ... on Post {
              slug
              title
              newsArticle {
                title
                slug
                preview
                date
                content
                author
                asset {
                  altText
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  }
}
`

function NewsArticle() {
  const articleId = window.location.href.toString().split("/").slice(-1)[0]
  const { loading, error, data } = useQuery(GET_ARTICLE, {
    errorPolicy: "all"
  });
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {!loading ?
        <>
          {data.posts.nodes.length > 0 ?
            <>
              {data.posts.nodes.map((p) => {
                if (p.newsArticle.slug === articleId) {
                  return <ProgramContainer>
                          <BannerContainer>
                            <Title dangerouslySetInnerHTML={{__html: p.newsArticle.title}}/>
                            {p.newsArticle.assetInArticleHeader ? 
                            <HeaderImageContainer>
                              <HeaderImage src={p.newsArticle.asset.sourceUrl} alt={p.newsArticle.asset.altText} />
                            </HeaderImageContainer>
                            :
                            <></>
                            }
                          </BannerContainer>
                          <OverviewWrapper dangerouslySetInnerHTML={{__html: p.newsArticle.content.replaceAll('<br />', '')}}/>
                          <BlogRecs>
                            <BlogGrid>
                              {p.newsArticle.recommendedPosts.map((post, index) => {
                                if (post.post) {
                                  return <BlogLink key={`${index}${post.post.newsArticle.slug}`} onClick={() => {navigate(`/news/${post.post.newsArticle.slug}`, {state: {articleId: post.post.slug}})}}>
                                        <BlogCard>
                                          <BlogPreviewImage src={post.post.newsArticle.asset.sourceUrl} />
                                          <BlogPreviewText>
                                            <BlogRecPreviewTitle>{post.post.newsArticle.title}</BlogRecPreviewTitle>
                                            <BlogContentPreview>
                                              {post.post.newsArticle.preview.substring(0, 150).trim() + "..."}
                                            </BlogContentPreview>
                                            <BlogContentPreviewMobile>
                                              {post.post.newsArticle.preview.substring(0, 100).trim() + "..."}
                                            </BlogContentPreviewMobile>
                                          </BlogPreviewText>
                                        </BlogCard>
                                      </BlogLink>
                                }
                              })}
                            </BlogGrid>
                          </BlogRecs>
                        </ProgramContainer>
                }
              })}
            </>
            :
            <ErrorSection/>
          }
        </>
        :
        <></>
      }
    </>
  )
}

export default NewsArticle
