import styled from "styled-components"

export const Banner = styled.div`
  font-family: poppins;
  font-weight: 300;
  width: 100%;
  padding: 40px 0;

  h1 {
    font-size: 32px;
    padding-bottom: 20px;
  }

  @media (max-width: 1500px) {
    padding: 0px 60px;
    h1 {
      font-size: 28px;
    }
  }

  @media (max-width: 1000px) {
    h1 {
      font-size: 24px;
    }
  }

  @media (max-width: 700px) {
    padding: 0 20px;
    p {
      font-size: 14px;
    }
  }

  @media (min-width: 1500px) {
    padding: 0px 150px;
  }

  @media (min-width: 2000px) {
    padding: 0px 300px;
  }
`

export const BannerTextMask = styled.div`
  margin-top: 60px;
  a {
    color: inherit;
    text-decoration: underline;
  }

  @media (max-width: 500px) {
    p {
      margin-bottom: 20px;
    }
  }
`
export const BannerButton = styled.button`
  padding: 1rem 2rem;
  margin: 1rem 0;
  height: fit-content;
  background-color: #ffcb00;
  border: none;
  border-radius: 30px;
  font-weight: 400;
  font-size: inherit;
  text-decoration: none;
  color: #333333;
  box-shadow: 0px 0px 15px 0px rgba(255, 203, 0, 0.5);
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(94, 158, 214, 1);
  }

  @media (max-width: 700px) {
    width: 100%;
    box-sizing: border-box;
  }
`

export const SectionTitle = styled.h1`
  font-size: 32px;
  margin: 40px 0;

  @media (max-width: 2000px) {
    padding: 0px 150px;
  }

  @media (max-width: 1500px) {
    font-size: 28px;
    padding: 0px 60px;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
    margin-bottom: 0;
  }

  @media (min-width: 2000px) {
    padding: 0px 300px;
  }
`

export const MediaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`

export const MediaGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 30px 0 0 0;
  box-sizing: border-box;
  padding: 0 60px;

  @media (max-width: 700px) {
    padding: 0 0;
    grid-gap: 0;
  }

  @media (min-width: 1500px) {
    padding: 0px 150px;
  }

  @media (min-width: 2000px) {
    padding: 0px 300px;
  }
`

export const MediaCard = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: row;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  @media (max-width: 700px) {
    height: fit-content;
    width: fit-content;
    align-items: center;
    margin: 10px;
  }
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0 0 0;

  @media (max-width: 700px) {
    margin: 0 0 0 10px;
    font-size: 18px;
  }
  @media (max-width: 650px) {
    font-size: 14px;
  }
`

export const MediaPreviewImage = styled.img`
  height: 300px;
  object-fit: contain;
  align-self: center;

  @media(max-width: 1000px) {
    width: 50%;
    height: fit;
  }

  @media (max-width: 700px) {
    width: 45%;
    height: 180px;
  }

  @media (max-width: 600px) {
    height: 120px;
  }
`

export const MediaContentPreview = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin: 10px 0 0 0;

  @media (max-width: 700px) {
    display: none;
  }
`

export const MediaLink = styled.div`
  :hover {
    cursor: pointer;
  }
`

export const MediaPreviewText = styled.div`
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
`;

export const MediaContentPreviewMobile = styled.p`
  margin: 10px;
  font-size: 14px;

  @media (min-width: 701px) {
    display: none;
  }
  @media (max-width: 400px) {
    display: none;
  }
`;

export const MediaQuote = styled.div`
  border-left: 3px solid #ffcb00;
  margin: 1rem 0;
  padding: 1rem;

  @media(max-width: 700px) {
    font-size: 14px;
  }

  @media (max-width: 400px) {
    display: none;
  }
`

export const MediaChevron = styled.img`
  position: absolute;
  z-index: 3;
  width: 40px;
  right: 10px;
  bottom: 10px;

  @media (max-width: 800px) {
    display: none;
  }

  // @media (max-width: 1200px) {
  //   width: 100px;
  // }

  // @media (max-width: 1000px) {
  //   width: 75px;
  // }

  // @media (max-width: 700px) {
  //   bottom: 30px;
  //   right: 20px;
  // }

  // @media (max-width: 500px) {
  //   width: 50px;
  //   bottom: calc(30px + 5%);
  // }

  // @media (min-width: 1500px) {
  //   bottom: 120px;
  //   right: 150px;
  // }

  // @media (min-width: 2000px) {
  //   right: 300px;
  // }
`
