import styled from "styled-components"

export const EventContainer = styled.div`
  padding: 20px 60px 50px 60px;

  @media (max-width: 700px) {
    padding: 20px;
  }

  @media (min-width: 1500px) {
    padding: 20px 150px 50px 150px;
  }

  @media (min-width: 2000px) {
    padding: 20px 300px 50px 300px;
  }
`;

export const EventCard = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 100px 1fr 350px;
  grid-gap: 3rem;

  background: ${props =>
    props.occurred === "true" ? "rgba(255,255,255,.5)" : "transparent"
  };

  @media (max-width: 1000px) {
    grid-template-columns: none;
    grid-gap: 0;
  }
`;

export const EventDate = styled.div`
  text-align: center;
`;

export const EventMonth = styled.p`
  @media (max-width: 1000px) {
    display: none;
  }
`
export const EventDay = styled.h1`
  @media (max-width: 1000px) {
    display: none;
  }
`

export const EventContent = styled.div`
  li, ul {
    font-size: 20px;
    font-weight: 300;

    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }
`;

export const EventTitle = styled.h1`
  margin: 0.5rem 0;
`

export const EventHeading = styled.div`
  margin-bottom: 1rem;
`

export const EventChip = styled.div`
  display: inline-flex;
  padding: 5px 15px;
  border: 2px solid #ffcb00;
  border-radius: 25px;
  margin: 0.5rem 0;
`

export const EventImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  align-self: center;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const EventButton = styled.button`
  padding: 1rem 2rem;
  margin: 1rem 0;
  height: fit-content;
  display: grid;
  background-color: #ffcb00;
  border: none;
  border-radius: 30px;
  font-weight: 400;
  font-size: 20px;
  text-decoration: none;
  color: #333333;
  box-shadow: 0px 0px 15px 0px rgba(255, 203, 0, 0.5);
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(94, 158, 214, 1);
  }

  @media (max-width: 1200px) {
    font-size: inherit;
  }

  @media (max-width: 700px) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const MonthSeparator = styled.div`
  margin: 2rem 0;

  @media (max-width: 100px) {
    margin: 0;
  }
`

export const EventTabs = styled.div`
  margin-top: 5rem;
  display: flex;
  justify-content: left;
  @media (max-width: 1000px) {
    justify-content: center;
  }
`

export const TabNav = styled.ul`
  display: flex;
  align-items: center;
  padding-left: 0px;
  border-radius: 2rem;
  margin-bottom: 0;
  padding: 50px 60px 0px 60px;

  @media (min-width: 1500px) {
    padding: 50px 150px 0px 150px;
  }

  @media (min-width: 2000px) {
    padding: 50px 300px 0px 300px;
  }

  @media (max-width: 1000px) {
    padding: 10px 10px 0 10px;
  }

  :first-child {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  :last-child {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }

`

export const Tab = styled.li`
  padding: 1rem 2rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  border: 2px solid #ffcb00;
  z-index: 1;

  background: ${props =>
    props.activetab === "true" ? "#ffcb00" : "transparent"
  };
`

export const TabContent = styled.div`

`