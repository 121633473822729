import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { CilarProvider } from "./contexts/cilarContext"

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <CilarProvider>
      <App/>
    </CilarProvider>
  </React.StrictMode>
)
