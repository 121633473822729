import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import {
  ProgramContainer,
  BannerContainer,
  Title,
  OverviewWrapper,
} from '../NewsArticle/newsArticleStyle'
import {
  MediaArticleButton
} from './mediaArticleStyle'
import { gql, useQuery } from '@apollo/client'
import ErrorSection from "../../components/Error/error";

const GET_ARTICLE = gql`
query GetMediaArticle($articleTitle: String) {
  allMediaPosts(where: {title: $articleTitle}) {
    nodes {
      cilarMedia {
        title
        content
        link
      }
    }
  }
}
`

function MediaArticle() {
  const { state } = useLocation();
  const { articleTitle } = state;
  const { loading, error, data } = useQuery(GET_ARTICLE, {
    variables: { "articleTitle": articleTitle }
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {!loading && !error ?
        <ProgramContainer>
          <BannerContainer>
            <Title dangerouslySetInnerHTML={{__html: data.allMediaPosts.nodes[0].cilarMedia.title}}/>
          </BannerContainer>
          <OverviewWrapper dangerouslySetInnerHTML={{__html: data.allMediaPosts.nodes[0].cilarMedia.content.replaceAll('<br />', '')}}/>
          {data.allMediaPosts.nodes[0].cilarMedia.link ?
            <MediaArticleButton href={data.allMediaPosts.nodes[0].cilarMedia.link} target="_blank">Read Source → </MediaArticleButton>
            :
            <></>
          }
        </ProgramContainer>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default MediaArticle
