import React from "react"
import { gql, useQuery } from '@apollo/client'
import Members from "../../components/Members/members";
import HeadingSplitLayout from "../../components/HeadingSplitLayout/headingSplitLayout";
import {
  MembersContainer,
  MembersWrapper,
  MembersHeaderWrapper,
  MembersPicturesWrapper
} from '../Community/communityStyle'
import ErrorSection from "../../components/Error/error";

const GET_TEAM= gql`
query GetTeam {
  allTeam {
    nodes {
      title
      categories(first: 1) {
        edges {
          node {
            name
          }
        }
      }
      aboutHeading {
        title
        content
        asset {
          altText
          sourceUrl
        }
      }
      teamMembers {
        title
        content
        members {
          name
          titles {
            title
          }
          description
          asset {
            altText
            sourceUrl
          }
        }
      }
    }
  }
}
`;

function TeamPage() {
  const { loading, error, data } = useQuery(GET_TEAM);

  return (
    <>
      {!loading && !error ?
      <>
        {data.allTeam.nodes.map(section => {
          switch(section.categories.edges[0].node.name) {
            case ('Team Heading'):
              return <HeadingSplitLayout key={section.title} data={section}/>
            case ('Team Members'):
              return <MembersContainer key={section.teamMembers.title}>
                      <MembersWrapper>
                        <MembersHeaderWrapper>
                          <div>
                            <h1 dangerouslySetInnerHTML={{__html: section.teamMembers.title}}/>
                          </div>
                          <p dangerouslySetInnerHTML={{__html: section.teamMembers.content}}/>
                        </MembersHeaderWrapper>
                        <MembersPicturesWrapper>
                          <Members members={section.teamMembers.members}/>
                        </MembersPicturesWrapper>
                      </MembersWrapper>
                    </MembersContainer>
            default:
              return <></>
          }
        })}
      </>
      :
      <>
        {error ?
          <ErrorSection/>
          :
          <></>
        }
      </>
      }
    </>
  )
}

export default TeamPage
