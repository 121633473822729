import styled from "styled-components"

export const LoaderContainer = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: white;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 15;
  top: 0;
  left: 0;
  pointer-events: ${props => (props.visible === "true" ? "auto" : "none")};
  opacity: ${props => (props.visible === "true" ? 1 : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity ease-in-out 0.5s;
`

export const LoaderGif = styled.img`
  width: 500px;

  @media (max-width: 800px) {
    width: 300px;
  }

  @media (max-width: 500px) {
    width: 200px;
  }
`
