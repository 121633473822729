import React from "react"
import { gql, useQuery } from '@apollo/client'
import HeadingSplitLayout from "../../components/HeadingSplitLayout/headingSplitLayout";
import JobListing from "../../components/JobListing/jobListing";
import styled from "styled-components"
import ErrorSection from "../../components/Error/error";

const GET_CAREERS = gql`
query GetCareers {
  allCareers {
    nodes {
      title
      categories(first: 1) {
        edges {
          node {
            name
          }
        }
      }
      aboutHeading {
        title
        content
        asset {
          altText
          sourceUrl
        }
        metaDescription
      }
      jobListings {
        title
        description
        applicationDeadline
        link
        salaryRange
        positionType
        location
        benefits
      }
    }
  }
}
`;

const CareerHeading = styled.h1`
  margin: 5rem 3.5rem;
  font-size: 24px;

  @media (max-width: 1200px) {
    font-size: 24px;
  }

  @media (max-width: 1000px) {
    font-size: 18px;
  }

  @media (min-width: 1500px) {
    margin: 5rem 9rem;
  }

  @media (min-width: 2000px) {
    font-size: 36px;
    margin: 5rem 19rem;
  }

  @media (max-width: 700px) {
    margin: 1rem;
  }
`;

function CareersPage() {
  const { loading, error, data } = useQuery(GET_CAREERS);
  
  return (
    <>
      {!loading && !error ?
      <>
        {data.allCareers.nodes.map(section => {
          switch(section.categories.edges[0].node.name) {
            case ('Career Heading'):
              return <HeadingSplitLayout key={section.title} data={section}/>
            case ('Job Listing'):
              return <JobListing key={section.title} data={section}/>
            default:
              return <></>
          }
        })}
        {data.allCareers.nodes.length === 1 ?
          <>
            <CareerHeading>No positions currently available.</CareerHeading>
          </>
          :
          <></>
        }
      </>
      :
      <>
        {error ?
          <ErrorSection/>
          :
          <></>
        }
      </>
      }
    </>
  )
}

export default CareersPage
