import styled from "styled-components"
import {
  TextMask,
  SlideTitle,
  SlideText,
} from "../../components/globalStyle"

export const PillarBannerContainer = styled.div`
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-columns: 55% 45%;
  grid-template-areas: "image content";
  border-bottom: 2px solid #ffcb00;
  @media (max-width: 800px) {
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    height: 90vh;
    grid-template-areas: "image";
  }
`

export const PillarBannerImageWrapper = styled.div`
  width: 100%;
  height: 50.25vw;
  position: relative;
  grid-area: image;
  z-index: 2;
  overflow: hidden;
  @media (max-width: 800px) {
    height: 100%;
  }
`

export const PillarBannerImage = styled.img`
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 800px) {
    height: 100%;
    object-fit: cover;
  }
`

export const PillarBannerGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0) 0%,
    rgba(51, 51, 51, 0.6) 50%,
    rgba(51, 51, 51, 0.2) 100%
  );
  display: flex;
  align-items: center;
  padding: 0 60px;
  bottom: 0;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background: linear-gradient(
      180deg,
      rgba(51, 51, 51, 0) 0%,
      rgba(51, 51, 51, 0.7) 70%,
      rgba(51, 51, 51, 0.5) 100%
    );
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 120px;
  }
`

export const PillarTitle = styled(SlideTitle)`
  color: white;
  position: relative;
  z-index: 1;
  width: 100%;
`

export const PillarBannerContentWrapper = styled.div`
  width: 100%;
  grid-area: content;
  padding: 0 100px 20% 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  /* @media (max-width: 1200px) {
    padding: 0 80px 0 60px;
  }

  @media (max-width: 850px) {
    padding: 0 60px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px 0 150px;
    justify-content: center;
  }

  @media (min-width: 2000px) {
    padding: 0 300px 0 150px;
  } */
`

export const BannerDescMask = styled(TextMask)`
  margin-top: 60px;

  @media (max-width: 800px) {
    display: none;
  }
`

export const BannerDesc = styled(SlideText)`
  width: 100%;
  position: relative;
`

export const MobileBannerDescMask = styled(TextMask)`
  display: none;
  margin-top: 30px;
  margin-bottom: 120px;

  @media (max-width: 800px) {
    display: block;
  }

  @media (max-width: 500px) {
    margin-bottom: 60px;
  }
`

export const MobileBannerDesc = styled(BannerDesc)`
  display: none;
  color: white;

  @media (max-width: 800px) {
    display: block;
  }
`

export const PillarContainerLeft = styled.div`
  width: calc(100% - 140px);
  min-height: 450px;
  margin: 200px auto auto 0;
  display: grid;
  grid-template-columns: 48% 1fr;
  grid-gap: 80px;
  grid-template-areas: "content image";
  background-color: #ffcb00;
  padding: 30px 10px 72px 60px;
  position: relative;

  /* @media (max-width: 800px) {
    grid-template-columns: 65% 1fr;
    grid-template-areas:
      "image ."
      "content content";
  } */

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    /* width: 10vw;
    height: 10vw; */
  }

  @media (max-width: 860px) {
    display: flex;
    flex-direction: column;
    width: 95%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 120px;
  }
  /* @media (min-width: 1200px) {
    min-height: 550px;
    width: calc(100% - 210px);
  } */
  ::before {
    position: absolute;
    content: " ";
    display: block;
    left: -100vw;
    top: -50px;
    width: 100%;
    border-style: solid;
    border-width: 0 100vw 50px 0;
    border-color: transparent transparent #ffcb00 transparent;
  }
  ::after {
    position: absolute;
    content: " ";
    display: block;
    left: -100vw;
    bottom: 0px;
    width: 100%;
    border-style: solid;
    border-width: 0 100vw 50px 0;
    border-color: transparent transparent #9b9b9b transparent;
  }
`

export const PillarContainerRight = styled(PillarContainerLeft)`
  margin: 200px 0 auto auto;
  grid-template-columns: 385px 1fr;
  grid-gap: 20px;
  grid-template-areas: "image content";
  padding-right: 50px;
  padding-left: 35px;

  @media (min-width: 1200px) {
    padding-left: 70px;
    grid-template-columns: 510px 1fr;

    /* grid-template-columns: 1fr 560px; */
    grid-gap: 50px;
  }

  @media (max-width: 860px) {
    margin-top: 120px;
  }
  ::before {
    position: absolute;
    content: " ";
    display: block;
    left: -100%;
    top: -50px;
    width: 100%;
    border-style: solid;
    border-width: 50px 100vw 0 0;
    border-color: transparent #ffcb00 transparent transparent;
  }
  ::after {
    position: absolute;
    content: " ";
    display: block;
    left: -100%;
    bottom: 0px;
    width: 100%;
    border-style: solid;
    border-width: 50px 100vw 0 0;
    border-color: transparent #9b9b9b transparent transparent;
  }
`

export const OverflowWrapper = styled.div`
  overflow-x: hidden;
`
