import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import LargeLogo from "../../images/large-logo.png"
import { gsap } from "gsap"
import {
  LandingContainer,
  LandingGradient,
  LandingTitle,
  LandingTitleMask,
  LandingArrowSmall,
  LandingBackground,
  LandingBackgroundMobile,
  LandingSubtitle,
} from "./landingStyle"

function Landing({ data }) {
  const {
    initialLandingAnimationCompleted,
    textSlideUp,
  } = useContext(CilarContext)
  
  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        gsap.to("#smallArrow", {
          autoAlpha: 1,
          duration: 0.5,
          ease: "power1.out",
        })
        textSlideUp("#landingTitle")
        if (data.content != '') {
          textSlideUp("#landingSubTitle")
        }
      }, 2000)
    } else {
      gsap.to("#smallArrow", {
        autoAlpha: 1,
        duration: 0.5,
        ease: "power1.out",
      })
      textSlideUp("#landingTitle")
      if (data.content != '') {
        textSlideUp("#landingSubTitle")
      }
    }

    gsap.to("#smallArrow", { y: -30, duration: 1.5, yoyo: true, repeat: -1 })
  }, [])
  
  return (
    <>
      <LandingContainer key={data.title}>
        <LandingGradient>
          <LandingTitleMask>
            <LandingTitle id="landingTitle" dangerouslySetInnerHTML={{__html: data.title}}/>
            {data.content === '' ?
            <></>
            :
            <LandingSubtitle id="landingSubTitle" dangerouslySetInnerHTML={{__html: data.content}}/>
            }
          </LandingTitleMask>
          <LandingArrowSmall id="smallArrow" src={LargeLogo} />
        </LandingGradient>
        <LandingBackground 
          src={data.webAsset.sourceUrl}
          alt={data.webAsset.altText}
        />
        <LandingBackgroundMobile
          src={data.mobileAsset.sourceUrl}
          alt={data.mobileAsset.altText}
        />
      </LandingContainer>
    </>
  )
}

export default Landing