import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import {
  SideContainer,
  SideContentWrapper,
  SideTitleMask,
  SideTitle,
  SideDescMask,
  SideDesc,
  SideImage,
} from './SplitLayoutStyle'

function TextRightSection({ data }) {
  const {
    textSlideUp,
  } = useContext(CilarContext)

  useEffect(() => {
    textSlideUp("#dnaTitle")
    textSlideUp(".dnaDesc")
  }, [])

  return (
    <>
      <SideContainer imageside="left">
        <SideImage
          src={data.asset.sourceUrl}
          alt={data.asset.altText}
        />
        <SideContentWrapper imageside="left">
          <SideTitleMask>
            <SideTitle id="dnaTitle" dangerouslySetInnerHTML={{ __html: data.title }} />
          </SideTitleMask>
          <SideDescMask>
            <SideDesc className="dnaDesc" dangerouslySetInnerHTML={{ __html: data.content }} />
          </SideDescMask>
        </SideContentWrapper>
      </SideContainer>
    </>
  )

}

export default TextRightSection