import React from "react"
import Landing from "../../components/LandingBanner/Landing"
import { gql, useQuery } from '@apollo/client'
import {
  Banner,
  BannerTextMask,
  WhiteLogo,
  SponsorsWrapper,
  Sponsors,
  SponsorBubble,
  SponsorImg,
} from './sponsorshipsStyle'
import ErrorSection from "../../components/Error/error"

const GET_SPONSORSHIP = gql`
query GetSponsorship {
  allSponsorshipSections {
    nodes {
      title
      categories(first: 10) {
        edges {
          node {
            name
          }
        }
      }
      banner {
        content
        asset {
          altText
          sourceUrl
        }
      }
      landingBanner {
        title
        content
        webAsset {
          altText
          sourceUrl
        }
        mobileAsset {
          altText
          sourceUrl
        }
      }
      sponsors {
        sponsors {
          sponsor {
            altText
            sourceUrl 
          }
        }
      }
    }
  }
}
`;

function Sponsorships() {
  const { loading, error, data } = useQuery(GET_SPONSORSHIP);

  return (
    <>
      {!loading && !error ?
        <>
          {data.allSponsorshipSections.nodes.map(section => {
            switch(section.categories.edges[0].node.name) {
              case ('Sponsorship Heading'):
                return <Landing key={section.title} data={section.landingBanner} />
              case ('Sponsorship Banner'):
                return <Banner key={section.title}>
                        <BannerTextMask id="bannerText" dangerouslySetInnerHTML={{__html: section.banner.content}}/>
                        {section.banner.asset ?
                          <WhiteLogo
                            src={section.banner.asset.sourceUrl}
                            alt={section.banner.asset.altText}
                          />
                          :
                          <></>
                        }
                      </Banner>
              case ('Sponsorship Sponsors'):
                return <SponsorsWrapper key={section.title}>
                        <Sponsors>
                          {section.sponsors.sponsors.map((sponsor, index) => (
                            <SponsorBubble key={`sponsor${index}`}>
                              <SponsorImg src={sponsor.sponsor.sourceUrl} alt={sponsor.sponsor.altText}></SponsorImg>
                            </SponsorBubble>
                          ))}
                        </Sponsors>
                      </SponsorsWrapper>
              default:
                return <></>
            }
          })}
        </>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default Sponsorships
