import React, { useContext } from "react"
import styled, { createGlobalStyle } from "styled-components"
import { CilarContext } from "../contexts/cilarContext"

// Spacing Guidelines

// REGULAR SCREEN
// 60px left and right space from edge of screen

// UNDER 700PX WIDTH
// 20px left and right space from edge of screen

// OVER 1500PX WIDTH
// 150px left and right space from edge of screen

// OVER 2000PX WIDTH
// 300px left and right space from edge of screen

const GlobalStyle = createGlobalStyle`
  html {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    width: 100%;
    margin: 0;
    padding: 0;
    position: ${props => (props.visible ? "fixed" : "inherit")};
    background-color: #FAFAFA;
    font-family: poppins, sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.1;
    margin: 0;
    color: black;
  }

  p {
    font-weight: 300;
    margin: 0;
    color: black;
    line-height: 1.4;
    font-size: 20px;

    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }

  div, span, button {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    background-color: #FFCB00;
  }

  .grecaptcha-badge {
    visibility: hidden!important;
  }

`

export const TextMask = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 1% 0;
  overflow: hidden;
`

export const SlideTitle = styled.h1`
  transform: translateY(100%);
  font-size: 80px;

  @media (max-width: 1200px) {
    font-size: 64px;
  }

  @media (max-width: 1000px) {
    font-size: 50px;
  }

  @media (min-width: 2000px) {
    font-size: 120px;
  }
`

export const SlideSubTitle = styled.h2`
  transform: translateY(100%);
  font-size: 72px;

  @media (max-width: 1200px) {
    font-size: 56px;
  }

  @media (max-width: 1000px) {
    font-size: 44px;
  }

  @media (min-width: 2000px) {
    font-size: 100px;
  }
`

export const SlideSubSubTitle = styled.h3`
  transform: translateY(100%);
  font-size: 40px;

  @media (max-width: 1200px) {
    font-size: 32px;
  }

  @media (max-width: 1000px) {
    font-size: 28px;
  }

  @media (min-width: 2000px) {
    font-size: 64px;
  }
`

export const SlideText = styled.p`
  transform: translateY(100%);
`

const GlobalStyleComponent = () => {
  const { disableScroll } = false//useContext(CilarContext)

  return <GlobalStyle visible={disableScroll} />
}

export default GlobalStyleComponent
