import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import { SlideSubTitle } from "../globalStyle"
import {
  SecondSideContainer,
  SideContentWrapper,
  SideSmallTitleMask,
  SideDescMask,
  SideDesc,
  ImageGrid,
  ImageCell,
  ImageGradient,
  ImageText,
  Img
} from './galleryStyle'

function Gallery ({ data }) {
  const { initialLandingAnimationCompleted, textSlideUp } = useContext(CilarContext);

  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        textSlideUp("#galleryTitle")
        textSlideUp("#galleryDesc")
      }, 2000)
    } else {
      setTimeout(() => {
        textSlideUp("#galleryTitle")
        textSlideUp("#galleryDesc")
      }, 1000)
    }
  }, [])

  return (
    <>
      <SecondSideContainer imageside="right">
        <SideContentWrapper imageside="right">
          <SideSmallTitleMask>
            <SlideSubTitle id="galleryTitle" dangerouslySetInnerHTML={{__html: data.galleryArticle.title}}/>
          </SideSmallTitleMask>
          <SideDescMask>
            <SideDesc id="galleryDesc" dangerouslySetInnerHTML={{__html: data.galleryArticle.content}}/>
          </SideDescMask>
        </SideContentWrapper>
        <ImageGrid>
          {data.galleryArticle.assets.map(image => {
            return <ImageCell key={image.name}>
                    <ImageGradient>
                      <ImageText>{image.name}</ImageText>
                    </ImageGradient>
                    <Img src={image.asset.sourceUrl} alt={image.asset.altText}/>
                  </ImageCell>
          })}
        </ImageGrid>
      </SecondSideContainer>
    </>
  )

}

export default Gallery