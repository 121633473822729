import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import {
  SideContainer,
  SideContentWrapper,
  SideTitleMask,
  SideTitle,
  SideDescMask,
  SideDesc,
  SideImage
} from './headingSplitLayoutStyle'

function HeadingSplitLayout ({ data }) {
  const { initialLandingAnimationCompleted, textSlideUp } = useContext(CilarContext);

  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        textSlideUp("#headingTitle")
        textSlideUp("#headingDesc")
      }, 2000)
    } else {
      setTimeout(() => {
        textSlideUp("#headingTitle")
        textSlideUp("#headingDesc")
      }, 1000)
    }
  }, [])

  return (
    <>
      <SideContainer imageside="right">
        <SideContentWrapper imageside="right">
          <SideTitleMask>
            <SideTitle id="headingTitle" dangerouslySetInnerHTML={{__html: data.aboutHeading.title}}/>
          </SideTitleMask>
          <SideDescMask>
            <SideDesc id="headingDesc" dangerouslySetInnerHTML={{__html: data.aboutHeading.content}}/>
          </SideDescMask>
        </SideContentWrapper>
        <SideImage src={data.aboutHeading.asset.sourceUrl} alt={data.aboutHeading.asset.altText}/>
      </SideContainer>
    </>
  )

}

export default HeadingSplitLayout