import React from "react"
import { gql, useQuery } from '@apollo/client'
import Events from "../../components/Events/events";
import HeadingSplitLayout from "../../components/HeadingSplitLayout/headingSplitLayout";
import ErrorSection from "../../components/Error/error";

const GET_EVENTS_PAGE = gql`
query GetEventsPage {
  pages(where: {title: "Events"}) {
    nodes {
      title
      aboutHeading {
        title
        content
        asset {
          altText
          sourceUrl
        }
      }
    }
  }
  allEvents {
    nodes {
      title
      event {
        title
        provisions
        location
        format
        description
        link
        eventDates {
          startTime
          startDate
          endTime
          endDate
        }
        asset {
          altText
          sourceUrl
        }
      }
    }
  }
}
`;

function EventsPage() {
  const { loading, error, data } = useQuery(GET_EVENTS_PAGE);

  return (
    <>
      {!loading && !error ?
      <>
        <HeadingSplitLayout key={data.pages.nodes[0].aboutHeading.title} data={data.pages.nodes[0]}/>
        <Events data={data.allEvents.nodes}/>
      </>
      :
      <>
        {error ?
          <ErrorSection/>
          :
          <></>
        }
      </>
      }
    </>
  )
}

export default EventsPage
