import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import { TextMask } from "../../components/globalStyle"
import * as styles from './programsStyle'
import { gql, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import ErrorSection from "../../components/Error/error"

const GET_PROGRAM = gql`
query GetPrograms {
  pages {
    nodes {
      title
      parentDatabaseId
      program {
        slug
        title
        content
        preview {
          applicationInformation
          content
          funding {
            fundingInformation
            asset {
              altText
              sourceUrl
            }
          }
          partners {
            title
            altText
            sourceUrl
          }
        }
      }
      template {
        templateName
        ... on Template_Innovators {
          programInnovators {
            bottomContent
            innovator {
              name
              description
              asset {
                altText
                sourceUrl
              }
              socials {
                link
                socialMedia
              }
            }
          }
        }
      }
      programs {
        content
        title
        asset {
          altText
          sourceUrl
        }
      }
    }
  }
}
`;

function ProgramsPage() {
  const {
    initialLandingAnimationCompleted,
    textSlideUp,
  } = useContext(CilarContext)
  const { loading, error, data } = useQuery(GET_PROGRAM);
  const navigate = useNavigate()

  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        textSlideUp("#programsBannerTitle")
        textSlideUp("#programsBannerText")
        textSlideUp("#programsSectionTitle")
      }, 2000)
    } else {
      setTimeout(() => {
        textSlideUp("#programsBannerTitle")
        textSlideUp("#programsBannerText")
        textSlideUp("#programsSectionTitle")
      }, 1000)
    }    
  }, [])

  return (
    <>
      {!loading && !error ?
      <>
        {data.pages.nodes.map((section, index) => {
          if (!section.parentDatabaseId && section.title === 'Programs') {
            return <styles.ProgramsBanner key={`Program-${index}-${section.title}`}>
                    <styles.ProgramsBannerContentWrapper>
                      <styles.ProgramsBannerTitleMask>
                        <styles.ProgramsBannerTitle id="programsBannerTitle" dangerouslySetInnerHTML={{__html: section.programs.title}}/>
                      </styles.ProgramsBannerTitleMask>
                      <TextMask>
                        <styles.ProgramsBannerText id="programsBannerText" dangerouslySetInnerHTML={{__html: section.programs.content}}/>
                      </TextMask>
                    </styles.ProgramsBannerContentWrapper>
                    <styles.ProgramsBannerImage
                      src={section.programs.asset.sourceUrl}
                      alt={section.programs.asset.altText}
                    />
                  </styles.ProgramsBanner>
          }
          else if (section.parentDatabaseId === 471) {
            return <styles.ProgramsSectionWrapper key={`Program-${index}-${section.title}`}>
                    <styles.ProgramsSection>
                      <TextMask>
                        <styles.ProgramsSectionTitle id="programsSectionTitle" dangerouslySetInnerHTML={{__html: section.program.title}}/>
                      </TextMask>
                      <styles.ProgramsSectionContent>
                        <styles.ProgramsSectionParagraph dangerouslySetInnerHTML={{__html: section.program.preview.content}}/>
                        <styles.ProgramsSectionRightWrapper>
                          { section.program.preview.applicationInformation != '' ?
                            <styles.ProgramsSectionSubSubHeading dangerouslySetInnerHTML={{__html: section.program.preview.applicationInformation}}/>
                            :
                            <></>
                          }
                          { section.program.preview.partners ?
                            <styles.ProgramsSectionPartnerWrapper>
                              <styles.ProgramsSectionSubSubHeading>
                                Partners:
                              </styles.ProgramsSectionSubSubHeading>
                              <styles.ProgramsSectionPartnerLogosWrapper>
                                {section.program.preview.partners.map((partner, i) => {
                                  return <styles.SponsorBubble key={`Partner-${i}-${section.title}`}>
                                          <styles.SponsorImg src={partner.sourceUrl} alt={partner.altText} />
                                        </styles.SponsorBubble>
                                })}
                              </styles.ProgramsSectionPartnerLogosWrapper>
                            </styles.ProgramsSectionPartnerWrapper>
                            :
                            <></>
                          }
                          <styles.ProgramsLinkButton onClick={() => {navigate(`/programs/${section.program.slug}`, {state: {programTitle: section.title}})}}>
                            Learn more →
                          </styles.ProgramsLinkButton>
                        </styles.ProgramsSectionRightWrapper>
                      </styles.ProgramsSectionContent>
                      { section.program.preview.funding.fundingInformation != '' ?
                        <styles.ProgramsSectionFacebookWrapper>
                          <styles.ProgramsSectionParagraph dangerouslySetInnerHTML={{__html: section.program.preview.funding.fundingInformation}}/>
                          <styles.ProgramsFacebookImg src={section.program.preview.funding.asset.sourceUrl} alt={section.program.preview.funding.asset.altText}/>
                        </styles.ProgramsSectionFacebookWrapper>
                        :
                        <></>
                      }
                    </styles.ProgramsSection>
                  </styles.ProgramsSectionWrapper>
          }
          else {
            return <></>
          }
        })}
      </>
      :
      <>
        {error ?
          <ErrorSection/>
          :
          <></>
        }
      </>
      }
    </>
  )
}

export default ProgramsPage
