import styled from "styled-components"
import { TextMask } from "../../components/globalStyle"

export const SearchContainer = styled.div`
  margin-top: 5rem;
  width: 100%;
  box-sizing: border-box;
  padding: 60px 60px 0 60px;

  @media (max-width: 700px) {
    padding: 20px;
  }

  @media (min-width: 1500px) {
    padding: 80px 150px;
  }

  @media (min-width: 2000px) {
    padding: 100px 300px;
  }
`;

export const SearchHeading = styled(TextMask)`
  font-size: 48px;
  font-weight: 600;
  @media (max-width: 1200px) {
    font-size: 36px;
  }
  @media (max-width: 800px) {
    font-size: 24px;
  }
`

export const SearchLink = styled.a`
  color: black;
  :hover {
    text-decoration: underline;
  }
`;

export const SearchTitle = styled(TextMask)`
  font-size: 22px;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (min-width: 2000px) {
    font-size: 22px;
  }
`;

export const SearchSnippet = styled.p`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
`;

export const BackButton = styled.a`
  :hover {
    text-decoration: underline;
  }
`;