import styled from "styled-components"
import {
    TextMask,
    SlideTitle,
    SlideSubSubTitle,
    SlideText,
  } from "../../components/globalStyle"

export const CommunityBannerContainer = styled.div`
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-areas: "content image";

  @media (max-width: 800px) {
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    height: 90vh;
    grid-template-areas: "image";
  }
`

export const CommunityBannerImageWrapper = styled.div`
  width: 100%;
  height: 50.94vw;
  position: relative;
  grid-area: image;
  z-index: 2;

  @media (max-width: 800px) {
    height: 100%;
  }
`

export const CommunityBannerImage = styled.img`
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 800px) {
    height: 100%;
    object-fit: cover;
  }
`

export const CommunityBannerGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0) 0%,
    rgba(51, 51, 51, 0) 50%,
    rgba(51, 51, 51, 0) 100%
  );
  display: flex;
  align-items: center;
  padding: 0 60px;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background: linear-gradient(
      180deg,
      rgba(51, 51, 51, 0) 0%,
      rgba(51, 51, 51, 0.7) 70%,
      rgba(51, 51, 51, 0.5) 100%
    );
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 300px;
  }
`

export const CommunityTitleMask = styled(TextMask)`
  position: relative;
  width: 70vw;
  z-index: 2;
  margin-top: 120px;

  @media (max-width: 800px) {
    display: none;
  }
`

export const CommunityTitle = styled(SlideTitle)`
  position: relative;
  z-index: 1;
  width: 100%;
`

export const CommunityBannerContentWrapper = styled.div`
  width: 100%;
  grid-area: content;
  padding: 0 100px 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 1200px) {
    padding: 0 80px 0 60px;
  }

  @media (max-width: 850px) {
    padding: 0 60px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px 0 150px;
    justify-content: center;
  }

  @media (min-width: 2000px) {
    padding: 0 150px 0 300px;
  }
`

export const BannerDescMask = styled(TextMask)`
  margin-top: 40px;

  @media (max-width: 1200px) {
    margin-top: 20px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`

export const BannerDesc = styled(SlideText)`
  width: 100%;
  position: relative;
`

export const MobileBannerDescMask = styled(TextMask)`
  display: none;
  margin-top: 30px;
  margin-bottom: 120px;

  @media (max-width: 800px) {
    display: block;
  }

  @media (max-width: 500px) {
    margin-bottom: 60px;
  }
`

export const MobileBannerDesc = styled(BannerDesc)`
  display: none;
  color: white;

  @media (max-width: 800px) {
    display: block;
  }
`

export const MobileBannerTitleMask = styled(TextMask)`
  display: none;

  @media (max-width: 800px) {
    display: block;
  }
`

export const MobileBannerTitle = styled(CommunityTitle)`
  display: none;
  color: white;
  width: calc(100vw - 40px);
  font-size: 48px;

  @media (max-width: 800px) {
    display: block;
  }
`

export const MiddleContainer = styled.div`
  width: calc(100% - 120px);
  margin: 150px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 120px;

  @media (max-width: 800px) {
    margin: 100px auto;
  }

  @media (max-width: 700px) {
    width: calc(100% - 40px);
    grid-template-columns: 1fr;
    grid-gap: 100px;
  }

  @media (min-width: 1500px) {
    width: calc(100% - 300px);
  }

  @media (min-width: 2000px) {
    width: calc(100% - 600px);
  }
`

export const MiddleImageContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  grid-gap: 30px;
`

export const MiddleImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const MiddleImage = styled.img`
  width: 100%;
  margin-top: ${props => `${props.margintop}px`};
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const MiddleCircle = styled.div`
  width: 100%;
  height: calc(((100vw - 120px) / 4) - 27.5px);
  display: grid;
  place-items: center;
  padding: 10px;
  border-radius: 50%;
  background-color: #333;
  margin-top: ${props => `${props.margintop}px`};
  margin-bottom: 30px;
  font-size: 36px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }

  @media (max-width: 700px) {
    font-size: 36px;
    height: calc(((100vw - 40px) / 2) - 15px);
  }

  @media (min-width: 1500px) {
    height: calc(((100vw - 300px) / 4) - 27.5px);
  }

  @media (min-width: 2000px) {
    height: calc(((100vw - 600px) / 4) - 27.5px);
  }
`

export const MiddleContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: 700px) {
    justify-content: flex-start;
  }
`

export const MiddleTitleMask = styled(TextMask)`
  margin-bottom: 30px;
`

export const MiddleTitle = styled(SlideSubSubTitle)`
  position: relative;
  z-index: 1;
  width: 100%;
`

export const MiddleText = styled(SlideText)`
  width: 100%;
  max-width: 500px;
  position: relative;

  @media (max-width: 700px) {
    max-width: 100%;
  }
`

export const MiddleImg = styled.img`
  width: ${props => (props.width ? `${props.width}%` : "80%")};
`

export const MembersContainer = styled.section`
  background-color: #656565;
`

export const MembersWrapper = styled.div`
  width: calc(100% - 120px);
  margin: 0 auto;
  padding: 40px 0;

  @media (max-width: 800px) {
    margin: 100px auto;
  }

  @media (max-width: 700px) {
    width: calc(100% - 40px);
    grid-template-columns: 1fr;
    grid-gap: 100px;
  }

  @media (min-width: 1500px) {
    width: calc(100% - 300px);
  }

  @media (min-width: 2000px) {
    width: calc(100% - 600px);
  }
`

export const MembersHeaderWrapper = styled.div`
  display: grid;
  grid-gap: 100px;
  grid-auto-flow: column;
  align-items: center;
  margin: 50px 0;

  h1 {
    font-size: 64px;
    color: #ffcb00;
  }

  p {
    color: #ffffff;
  }

  @media (max-width: 900px) {
    grid-gap: 20px;
    grid-auto-flow: row;
  }
`

export const MembersPicturesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
`