import styled from "styled-components"

export const PrivacyPolicyContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 60px;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 300px;
  }
`

export const PrivacyPolicyTitle = styled.h1`
  font-size: 48px;
  margin: 175px 0 30px 0;

  @media (max-width: 1200px) {
    font-size: 42px;
  }

  @media (max-width: 1000px) {
    font-size: 32px;
  }

  @media (min-width: 2000px) {
    font-size: 64px;
  }
`

export const PrivacyPolicyParagraph = styled.p`
  font-size: 16px;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

export const PrivacyPolicyHeading = styled.h2`
  font-size: 16px;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

export const PrivacyPolicyUl = styled.ul``

export const Body = styled.div`
  max-width: 900px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  p {
    margin-bottom: 20px;
    font-size: 16px;
  }

  ul,
  ol {
    margin-bottom: 30px;
  }

  li {
    font-size: 16px;
    font-weight: 300;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 22px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 16px;
  }

  a {
    color: #ffcb00;
  }
`