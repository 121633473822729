import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { 
  SearchContainer,
  SearchHeading,
  SearchLink, 
  SearchTitle,
  SearchSnippet,
  BackButton
} from "./searchStyle";
import { gql, useQuery } from "@apollo/client";
import ErrorSection from "../../components/Error/error";

const GET_SEARCH = gql`
query MyQuery($getAbout: Boolean!, $getCareers: Boolean!, $getCommunity: Boolean!, $getContact: Boolean!, $getEvents: Boolean!, $getImpact: Boolean!, $getMedia: Boolean!, $getSponsorship: Boolean!, $getTeam: Boolean!, $getWork: Boolean!, $posts: [ID]!, $pages: [ID]!){
  allAboutSections @include(if: $getAbout){
    nodes {
      title
      aboutHeading {
        title
        metaDescription
      }
    }
  }
  allCareers @include(if: $getCareers) {
    nodes {
      title
      aboutHeading {
        title
        metaDescription
      }
    }
  }
  allCommunity @include(if: $getCommunity) {
    nodes {
      title
      article {
        title
      }
      communityHeading {
        metaDescription
      }
    }
  }
  allContact @include(if: $getContact) {
    nodes {
      title
      contactHeading {
        title
        metaDescription
      }
    }
  }
  allEvents @include(if: $getEvents) {
    nodes {
      title
      databaseId
      event {
        title
        metaDescription
      }
    }
  }
  allImpactSections @include(if: $getImpact) {
    nodes {
      title
      impactDashboardHeading {
        title
        metaDescription
      }
    }
  }
  allMediaPosts @include(if: $getMedia) {
    nodes {
      title
      cilarMedia {
        title
        slug
        metaDescription
      }
    }
  }
  allSponsorshipSections @include(if: $getSponsorship) {
    nodes {
      title
      landingBanner {
        title
        metaDescription
      }
    }
  }
  allTeam @include(if: $getTeam) {
    nodes {
      title
      aboutHeading {
        title
        metaDescription
      }
    }
  }
  allWorkSections @include(if: $getWork) {
    nodes {
      title
      workHeader {
        title
        metaDescription
      }
    }
  }
  posts(where: {in: $posts}) {
    nodes {
      title
      databaseId
      categories(first: 1) {
        nodes {
          name
        }
      }
      newsArticle {
        title
        slug
        metaDescription
      }
      blogArticle {
        title
        slug
        metaDescription
      }
    }
  }
  pages(where: {in: $pages}) {
    nodes {
      title
      databaseId
      parentDatabaseId
      program {
        title
        slug
        metaDescription
      }
      programs {
        title
        metaDescription
      }
      newsAndUpdates {
        title
        metaDescription
      }
      privacyPolicy {
        title
        metaDescription
      }
      mediaHeading {
        title
        metaDescription
      }
      aboutHeading {
        title
        metaDescription
      }
    }
  }
}
`;

const getSearchVariables = (data) => {
  var searchVars = {
    getAbout: false,
    getCareers: false,
    getCommunity: false,
    getContact: false,
    getEvents: false,
    getImpact: false,
    getMedia: false,
    getSponsorship: false,
    getTeam: false,
    getWork: false,
    posts: [],
    pages: [],
  };
  for (var d in data) {
    switch(data[d].subtype) {
      case('post'):
        searchVars.posts.push(data[d].id);
        break;
      case('page'):
        searchVars.pages.push(data[d].id);
        break;
      case('about'):
        searchVars.getAbout = true;
        break;
      case('career'):
        searchVars.getCareers = true;
        break;
      case('community'):
        searchVars.getCommunity = true;
        break;
      case('contact'):
        searchVars.getContact = true;
        break;
      case('event'):
        searchVars.getEvents = true;
        break;
      case('impact'):
        searchVars.getImpact = true;
        break;
      case ('media'):
        searchVars.getMedia = true;
        break;
      case('sponsorship'):
        searchVars.getSponsorship = true;
        break;
      case('team'):
        searchVars.getTeam = true;
        break;
      case('work'):
        searchVars.getWork = true;
        break;
      default:
        break;
    }
  }
  if (searchVars.posts.length === 0 ) {
    searchVars.posts.push(0);
  }
  if (searchVars.pages.length === 0) {
    searchVars.pages.push(0);
  }
  return searchVars;
};

function SearchPage () {
  const navigate = useNavigate()
  const { state } = useLocation();
  const { results, search } = state;
  const searchVars = getSearchVariables(results);
  const { loading, error, data } = useQuery(GET_SEARCH, { variables: searchVars });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const getPage = (link, type, data) => {
    switch (type) {
      case ('blog'):
        navigate(`/blog/${link}`, {state: {articleTitle: data}});
        break;
      case ('news'):
        navigate(`/news/${link}`, {state: {articleTitle: data}});
        break;
      case ('program'):
        navigate(`/programs/${link}`, {state: {programTitle: data}});
        break;
      case ('page'):
        navigate(`${link}`);
        break;
      case ('back'):
        navigate(-1);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {!loading && !error ?
        <SearchContainer>
          <BackButton onClick={() => {getPage('', 'back', '')}}>
            <SearchTitle>Back</SearchTitle>
          </BackButton>
          <SearchHeading>{`Results for '${search}'`}</SearchHeading>
          {results.map(link => {
            switch(link.subtype) {
              case('post'):
                return <div key={`post-${link.id}`}>
                        {data.posts.nodes.map(p => {
                          if ((p.databaseId === link.id) && (p.categories.nodes[0].name === "News")) {
                            return <div key={`blog-${link.id}`}>
                                    <SearchLink onClick={() => {getPage(p.newsArticle.slug, 'news', link.title)}}>
                                      <SearchTitle dangerouslySetInnerHTML={{__html: p.newsArticle.title}}/>
                                    </SearchLink>
                                    {p.newsArticle.metaDescription ?
                                      <SearchSnippet>{p.newsArticle.metaDescription}</SearchSnippet>
                                      :
                                      <></>
                                    }
                                  </div>
                          } else if ((p.databaseId === link.id) && (p.categories.nodes[0].name === "Blog")) {
                            return <div key={`news-${link.id}`}>
                                    <SearchLink onClick={() => {getPage(p.blogArticle.slug, 'blog', link.title)}}>
                                      <SearchTitle dangerouslySetInnerHTML={{__html: p.blogArticle.title}}/>
                                    </SearchLink>
                                    {p.blogArticle.metaDescription ?
                                      <SearchSnippet>{p.blogArticle.metaDescription}</SearchSnippet>
                                      :
                                      <></>
                                    }
                                  </div>
                          }
                        })}
                      </div>
              case('page'):
                return <div key={`page-${link.id}`}>
                      {data.pages.nodes.map(p => {
                        if (p.parentDatabaseId === 471 && p.databaseId === link.id) {
                          return <div key={link.id}>
                                  <SearchLink onClick={() => {getPage(p.program.slug, 'program', link.title)}}>
                                    <SearchTitle dangerouslySetInnerHTML={{__html: p.program.title}}/>
                                  </SearchLink>
                                  {p.program.metaDescription ?
                                    <SearchSnippet>{p.program.metaDescription}</SearchSnippet>
                                    :
                                    <></>
                                  }
                                </div>
                        } 
                        if (p.title === 'Privacy Policy' && p.databaseId === link.id) {
                          return <div key={link.id}>
                                  <SearchLink onClick={() => {getPage('/privacy-policy', 'page', '')}}>
                                    <SearchTitle dangerouslySetInnerHTML={{__html: p.privacyPolicy.title}}/>
                                  </SearchLink>
                                  {p.privacyPolicy.metaDescription ?
                                    <SearchSnippet>{p.privacyPolicy.metaDescription}</SearchSnippet>
                                    :
                                    <></>
                                  }
                                </div>
                        } else if (p.title === 'News & Updates' && p.databaseId === link.id) {
                          return <div key={link.id}>
                                  <SearchLink onClick={() => {getPage('/news', 'page', '')}}>
                                    <SearchTitle dangerouslySetInnerHTML={{__html: p.newsAndUpdates.title}}/>
                                  </SearchLink>
                                  {p.newsAndUpdates.metaDescription ?
                                    <SearchSnippet>{p.newsAndUpdates.metaDescription}</SearchSnippet>
                                    :
                                    <></>
                                  }
                                </div>
                        } else if (p.title === 'Blog' && p.databaseId === link.id) {
                          return <div key={link.id}>
                                  <SearchLink onClick={() => {getPage('/blog', 'page', '')}}>
                                    <SearchTitle dangerouslySetInnerHTML={{__html: p.newsAndUpdates.title}}/>
                                  </SearchLink>
                                  {p.newsAndUpdates.metaDescription ?
                                    <SearchSnippet>{p.newsAndUpdates.metaDescription}</SearchSnippet>
                                    :
                                    <></>
                                  }
                                </div>
                        } else if (p.title === 'Media' && p.databaseId === link.id) {
                          return <div key={link.id}>
                                  <SearchLink onClick={() => {getPage('/media', 'page', '')}}>
                                    <SearchTitle dangerouslySetInnerHTML={{__html: p.mediaHeading.title}}/>
                                  </SearchLink>
                                  {p.mediaHeading.metaDescription ?
                                    <SearchSnippet>{p.mediaHeading.metaDescription}</SearchSnippet>
                                    :
                                    <></>
                                  }
                                </div>
                        } else if (p.title === 'Events' && p.databaseId === link.id) {
                          return <div key={link.id}>
                                  <SearchLink onClick={() => {getPage('/events', 'page', '')}}>
                                    <SearchTitle dangerouslySetInnerHTML={{__html: p.aboutHeading.title}}/>
                                  </SearchLink>
                                  {p.aboutHeading.metaDescription ?
                                    <SearchSnippet>{p.aboutHeading.metaDescription}</SearchSnippet>
                                    :
                                    <></>
                                  }
                                </div>
                        }
                      })}
                    </div>
              case('about'):
                return <div key={link.id}>
                        <SearchLink onClick={() => {getPage('/about', 'page', '')}}>
                          <SearchTitle dangerouslySetInnerHTML={{__html: data.allAboutSections.nodes[0].aboutHeading.title}}/>
                        </SearchLink>
                        {data.allAboutSections.nodes[0].aboutHeading.metaDescription ?
                          <SearchSnippet>{data.allAboutSections.nodes[0].aboutHeading.metaDescription}</SearchSnippet>
                          :
                          <></>
                        }
                      </div>
              case('career'):
                return <div key={link.id}>
                      <SearchLink onClick={() => {getPage('/career', 'page', '')}}>
                        <SearchTitle dangerouslySetInnerHTML={{__html: data.allCareers.nodes[0].aboutHeading.title}}/>
                      </SearchLink>
                      {data.allCareers.nodes[0].aboutHeading.metaDescription ?
                        <SearchSnippet>{data.allCareers.nodes[0].aboutHeading.metaDescription}</SearchSnippet>
                        :
                        <></>
                      }
                    </div>
              case('community'):
                return <div key={link.id}>
                        <SearchLink onClick={() => {getPage('/community', 'page', '')}}>
                          <SearchTitle dangerouslySetInnerHTML={{__html: data.allCommunity.nodes[0].article.title.replaceAll("<br />", " ")}}/>
                        </SearchLink>
                        {data.allCommunity.nodes[0].communityHeading.metaDescription ?
                          <SearchSnippet>{data.allCommunity.nodes[0].communityHeading.metaDescription}</SearchSnippet>
                          :
                          <></>
                        }
                        
                      </div>
              case('contact'):
                return <div key={link.id}>
                        <SearchLink onClick={() => {getPage('/contact', 'page', '')}}>
                          <SearchTitle dangerouslySetInnerHTML={{__html: data.allContact.nodes[0].contactHeading.title}}/>
                        </SearchLink>
                        {data.allContact.nodes[0].contactHeading.metaDescription ?
                          <SearchSnippet>{data.allContact.nodes[0].contactHeading.metaDescription}</SearchSnippet>
                          :
                          <></>
                        }
                      </div>
              case('event'):
                return <div key={link.id}>
                        {data.allEvents.nodes.map(ev => {
                          if (ev.databaseId === link.id) {
                            return <div key={`event-${link.id}`}>
                                    <SearchLink onClick={() => {getPage('/events', 'page', '')}}>
                                      <SearchTitle dangerouslySetInnerHTML={{__html: ev.event.title}}/>
                                    </SearchLink>
                                    {ev.event.metaDescription ?
                                      <SearchSnippet>{ev.event.metaDescription}</SearchSnippet>
                                      :
                                      <></>
                                    }
                                  </div>
                          }
                        })}
                      </div>
              case('impact'):
                return <div key={link.id}>
                        <SearchLink onClick={() => {getPage('/impact', 'page', '')}}>
                          <SearchTitle dangerouslySetInnerHTML={{__html: data.allImpactSections.nodes[0].impactDashboardHeading.title}}/>
                        </SearchLink>
                        {data.allImpactSections.nodes[0].impactDashboardHeading.metaDescription ?
                          <SearchSnippet>{data.allImpactSections.nodes[0].impactDashboardHeading.metaDescription}</SearchSnippet>
                          :
                          <></>
                        }
                        
                      </div>
              case('media'):
                return <div key={link.id}>
                        <SearchLink onClick={() => {getPage(data.allMediaPosts.nodes[0].cilarMedia.slug, 'media', link.title)}}>
                          <SearchTitle dangerouslySetInnerHTML={{__html: data.allMediaPosts.nodes[0].cilarMedia.title}}/>
                        </SearchLink>
                        {data.allMediaPosts.nodes[0].cilarMedia.metaDescription ?
                          <SearchSnippet>{data.allMediaPosts.nodes[0].cilarMedia.metaDescription}</SearchSnippet>
                          :
                          <></>
                        }
                      </div>
              case('sponsorship'):
                return <div key={link.id}>
                        <SearchLink onClick={() => {getPage('/sponsorships', 'page', '')}}>
                          <SearchTitle dangerouslySetInnerHTML={{__html: data.allSponsorshipSections.nodes[0].landingBanner.title.replaceAll("<br />", " ")}}/>
                        </SearchLink>
                        {data.allSponsorshipSections.nodes[0].landingBanner.metaDescription ?
                          <SearchSnippet>{data.allSponsorshipSections.nodes[0].landingBanner.metaDescription}</SearchSnippet>
                          :
                          <></>
                        }
                      </div>
              case('team'):
                return <div key={link.id}>
                      <SearchLink onClick={() => {getPage('/team', 'page', '')}}>
                        <SearchTitle dangerouslySetInnerHTML={{__html: data.allTeam.nodes[0].aboutHeading.title}}/>
                      </SearchLink>
                      {data.allTeam.nodes[0].aboutHeading.metaDescription ?
                        <SearchSnippet>{data.allTeam.nodes[0].aboutHeading.metaDescription}</SearchSnippet>
                        :
                        <></>
                      }
                    </div>
              case('work'):
                return <div key={link.id}>
                        <SearchLink onClick={() => {getPage('/work', 'page', '')}}>
                          <SearchTitle dangerouslySetInnerHTML={{__html: data.allWorkSections.nodes[0].workHeader.title.replaceAll("<br />", " ")}}/>
                        </SearchLink>
                        {data.allWorkSections.nodes[0].workHeader.metaDescription ?
                          <SearchSnippet>{data.allWorkSections.nodes[0].workHeader.metaDescription}</SearchSnippet>
                          :
                          <></>
                        }
                      </div>
              default:
                return <></>
            }
          })}
        </SearchContainer>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default SearchPage