// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font-family: "Poppins", sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    line-height: 1.1;
    margin: 0;
    color: black;
  }

  p {
    font-weight: 300;
    margin: 0;
    color: black;
    line-height: 1.4;
    font-size: 20px;

    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }

  div, span, button {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    cursor: pointer;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    background-color: #FFCB00;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,SAAS;IACT,UAAU;IACV,yBAAyB;IACzB,kCAAkC;EACpC;;EAEA;IACE,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;IACT,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,SAAS;IACT,YAAY;IACZ,gBAAgB;IAChB,eAAe;;IAEf;MACE,eAAe;IACjB;EACF;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,qBAAqB;IACrB,eAAe;EACjB;;EAEA;IACE,UAAU;IACV,qEAAqE;IACrE,yBAAyB;AAC7B","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');\n\nhtml {\n    width: 100%;\n    margin: 0;\n    padding: 0;\n  }\n\n  body {\n    width: 100%;\n    margin: 0;\n    padding: 0;\n    background-color: #FAFAFA;\n    font-family: \"Poppins\", sans-serif;\n  }\n\n  h1, h2, h3, h4, h5, h6 {\n    font-weight: 600;\n    line-height: 1.1;\n    margin: 0;\n    color: black;\n  }\n\n  p {\n    font-weight: 300;\n    margin: 0;\n    color: black;\n    line-height: 1.4;\n    font-size: 20px;\n\n    @media (max-width: 1200px) {\n      font-size: 16px;\n    }\n  }\n\n  div, span, button {\n    box-sizing: border-box;\n  }\n\n  a {\n    text-decoration: none;\n    cursor: pointer;\n  }\n\n  .swiper-pagination-bullet-active {\n    opacity: 1;\n    background: var(--swiper-pagination-color, var(--swiper-theme-color));\n    background-color: #FFCB00;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
