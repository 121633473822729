import styled from "styled-components"
import { SlideTitle } from "../../components/globalStyle"

export const ContactBanner = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 45% 55%;
  position: relative;

  @media (max-width: 800px) and (orientation: portrait) {
    height: 50vh;
    grid-template-columns: 45% 55%;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    height: fit-content;
    padding-top: 185px;
  }

  @media (max-width: 500px) {
    padding-top: 145px;
  }

  @media (min-width: 800px) and (orientation: portrait) {
    height: 50vh;
  }
`

export const ContactBannerContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #fafafa;

  @media (max-width: 700px) {
    height: fit-content;
    padding-bottom: 60px;
  }
`

export const ContactBannerTitle = styled(SlideTitle)`
  padding: 0 100px 0 60px;
  margin-top: 50px;

  @media (max-width: 850px) {
    padding: 0 60px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
    margin: 0;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 150px 0 300px;
  }
`

export const ContactBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 700px) {
    width: calc(100vw - 40px);
    margin: 0 auto 60px auto;
    height: calc(100vw - 40px);
  }
`