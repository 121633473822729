import styled from "styled-components"

const MemberContent = styled.div`
  div {
    margin: 0 0 10px 0;
  }

  h2 {
    color: #ffffff;
    font-weight: 300;
    margin: 0 0 5px 0;
  }

  p {
    color: #ffffff;
    font-size: 12px;
  }

  img {
    max-width: 250px;
  }

  @media (max-width: 800px) {
    text-align: center;

    h2 {
      font-size: 18px;
    }

    p {
      font-size: 10px;
    }

    img {
      max-width: 150px;
    }
  }
`

const MemberDesc = styled.p`
  margin-top: 1rem;
`;

function Members({ members }) {
  return(
    <>
      {members.map(member => (
        <MemberContent key={member.name}>
          <img src={member.asset.sourceUrl} alt={member.asset.altText} />
          <h2>{member.name}</h2>
          {member.titles.map(title => (
            <p key={title.title}>{title.title}</p>
          ))}
          {member.description ?
            <MemberDesc dangerouslySetInnerHTML={{__html: member.description}}/>
            :
            <></>
          }
        </MemberContent>
      ))}
    </>
  )

}

export default Members