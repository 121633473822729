import styled from "styled-components"

export const MediaArticleButton = styled.button`
  width: fit-content;
  padding: 1rem 2rem;
  margin: 1rem 3.5rem;
  height: fit-content;
  background-color: #ffcb00;
  border: none;
  border-radius: 30px;
  font-weight: 400;
  font-size: inherit;
  text-decoration: none;
  color: #333333;
  box-shadow: 0px 0px 15px 0px rgba(255, 203, 0, 0.5);
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(94, 158, 214, 1);
  }

  @media (max-width: 700px) {
    box-sizing: border-box;
    margin: 1rem 1rem;
  }
`