import styled from "styled-components"
import {
    TextMask,
    SlideTitle,
    SlideText,
    SlideSubTitle,
  } from "../../components/globalStyle"

export const ProgramsBanner = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 45% 55%;
  position: relative;

  @media (max-width: 800px) and (orientation: portrait) {
    height: 50vh;
    grid-template-columns: 45% 55%;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    height: fit-content;
    padding-top: 185px;
    background: #333;
  }

  @media (max-width: 500px) {
    padding-top: 145px;
  }

  @media (min-width: 800px) and (orientation: portrait) {
    height: 50vh;
  }
`

export const ProgramsBannerContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #333;

  @media (max-width: 800px) {
    height: fit-content;
    padding-bottom: 60px;
  }
`

export const ProgramsBannerTitleMask = styled(TextMask)`
  margin-bottom: 30px;
  margin-top: 50px;
`

export const ProgramsBannerTitle = styled(SlideTitle)`
  padding: 0 100px 0 60px;
  color: white;

  @media (max-width: 850px) {
    padding: 0 60px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
    margin: 0;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 150px 0 300px;
  }
`

export const ProgramsBannerText = styled(SlideText)`
  padding: 0 100px 0 60px;
  color: white;

  @media (max-width: 850px) {
    padding: 0 60px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 150px 0 300px;
  }
`

export const ProgramsBannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media (max-width: 800px) {
    width: calc(100vw - 120px);
    margin: 0 auto 60px auto;
    height: calc(100vw - 120px);
  }

  @media (max-width: 700px) {
    width: calc(100vw - 40px);
    margin: 0 auto 60px auto;
    height: calc(100vw - 40px);
  }
`

export const ProgramsSectionWrapper = styled.div`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 60px;

  @media (max-width: 700px) {
    padding: 60px;
  }

  @media (min-width: 800px) {
    margin-top: 60px;
  }

  @media (min-width: 1500px) {
    padding: 150px 150px 0px 150px;
  }
  
`

export const ProgramsSection = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 60px 60px 20px 60px;
  background: #f4f4f4;

  @media (max-width: 700px) {
    padding: 20px 20px 10px 20px;
  }
`

export const ProgramsSectionTitle = styled(SlideSubTitle)`
  font-size: 56px;
  max-width: 70%;

  @media (max-width: 1200px) {
    font-size: 48px;
    max-width: 75%;
  }

  @media (max-width: 1000px) {
    font-size: 36px;
    max-width: 100%;
  }

  @media (min-width: 2000px) {
    font-size: 84px;
  }
`

export const ProgramsSectionContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 120px;
  margin-top: 60px;

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
    grid-gap: 40px;
    margin-top: 40px;
  }
`

export const ProgramsSectionParagraph = styled(SlideText)`
  transform: translateY(0);
  margin-bottom:32px;
`

export const ProgramsSectionRightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ProgramsSectionSubSubHeading = styled.h4`
  font-size: 22px;
  margin-bottom: 16px;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (min-width: 2000px) {
    font-size: 22px;
  }
`

export const ProgramsSectionPartnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`

export const ProgramsSectionPartnerLogosWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`

export const SponsorBubble = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 100%;
  background-color: #333333;
  height: 75px;
  width: 75px;
  padding: 30px;
  margin: 0 30px 0 0;
  border: 1px solid #ffcb00;
  box-shadow: 5px 5px 20px;
  box-sizing: content-box;

  @media (max-width: 800px) {
    height: 60px;
    width: 60px;
  }

  @media (max-width: 550px) {
    height: 40px;
    width: 40px;
  }

  @media (max-width: 400px) {
    height: 35px;
    width: 35px;
  }
`

export const SponsorImg = styled.img`
  width: 100px;
  object-fit: contain;
`

export const ProgramsLinkButton = styled.button`
  width: 400px;
  max-width: 100%;
  height: fit-content;
  display: grid;
  place-items: center;
  background-color: #ffcb00;
  border: none;
  border-radius: 30px;
  font-weight: 400;
  font-size: 24px;
  text-decoration: none;
  color: #333333;
  box-shadow: 0px 0px 15px 0px rgba(255, 203, 0, 0.5);
  cursor: pointer;
  margin-top: 60px;
  padding: 15px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(94, 158, 214, 1);
  }
  @media (max-width: 1400px) and (max-height: 900px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    width: 100%;
    box-sizing: border-box;
  }
`

export const ProgramsSectionFacebookWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`

export const ProgramsFacebookImg = styled.img`
  width: 350px;
`