import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import Members from "../../components/Members/members"
import { TextMask } from "../../components/globalStyle"
import * as cs from "./communityStyle"
import { gql, useQuery } from '@apollo/client'
import ErrorSection from "../../components/Error/error"

const GET_COMMUNITY = gql`
query GetCommunity {
  allCommunity {
    nodes {
      title
      categories(first: 10) {
        edges {
          node {
            name
          }
        }
      }
      article {
        title
        content
        asset {
          altText
          sourceUrl
        }
      }
      communityMembers {
        title
        content
        members {
          name
          titles {
            title
          }
          asset {
            altText
            sourceUrl
          }
        }
      }
      galleryArticle {
        title
        content
        assets {
          name
          asset {
            altText
            sourceUrl
          }
        }
      }
    }
  }
}
`;

function CommunityPage () {
  const { initialLandingAnimationCompleted, textSlideUp } = useContext(
    CilarContext
  )
  const { loading, error, data } = useQuery(GET_COMMUNITY);

  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        textSlideUp("#communityBannerTitle")
        textSlideUp("#communityBannerDesc")
        textSlideUp("#communityBannerDescMobile")
        textSlideUp("#communityBannerTitleMobile")
        textSlideUp("#middleTitle")
        textSlideUp("#middleText")
      }, 2000)
    } else {
      setTimeout(() => {
        textSlideUp("#communityBannerTitle")
        textSlideUp("#communityBannerDesc")
        textSlideUp("#communityBannerDescMobile")
        textSlideUp("#communityBannerTitleMobile")
        textSlideUp("#middleTitle")
        textSlideUp("#middleText")
      }, 1000)
    }
  }, [])

  return (
    <>
      {!loading && !error ?
      <>
        {data.allCommunity.nodes.map(section => {
          switch(section.categories.edges[0].node.name) {
            case ('Community Article'):
              return <cs.CommunityBannerContainer key={section.article.title}>
                      <cs.CommunityBannerImageWrapper>
                        <cs.CommunityBannerGradient>
                          <cs.MobileBannerTitleMask>
                            <cs.MobileBannerTitle id="communityBannerTitleMobile" dangerouslySetInnerHTML={{__html: section.article.title}}/>
                          </cs.MobileBannerTitleMask>
                          <cs.MobileBannerDescMask>
                            <cs.MobileBannerDesc id="communityBannerDescMobile" dangerouslySetInnerHTML={{__html: section.article.content}}/>
                          </cs.MobileBannerDescMask>
                        </cs.CommunityBannerGradient>
                        <cs.CommunityBannerImage src={section.article.asset.sourceUrl} alt={section.article.asset.altText}/>
                      </cs.CommunityBannerImageWrapper>
                      <cs.CommunityBannerContentWrapper>
                        <cs.CommunityTitleMask>
                          <cs.CommunityTitle id="communityBannerTitle" dangerouslySetInnerHTML={{__html: section.article.title}}/>
                        </cs.CommunityTitleMask>
                        <cs.BannerDescMask>
                          <cs.BannerDesc id="communityBannerDesc" dangerouslySetInnerHTML={{__html: section.article.content}}/>
                        </cs.BannerDescMask>
                      </cs.CommunityBannerContentWrapper>
                    </cs.CommunityBannerContainer>
            case ('Community Gallery'):
              return <cs.MiddleContainer key={section.galleryArticle.title}>
                      <cs.MiddleImageContainer>
                        <cs.MiddleImageWrapper>
                          <cs.MiddleImage
                            src={section.galleryArticle.assets[0].asset.sourceUrl}
                            alt={section.galleryArticle.assets[0].asset.altText}
                          />
                          <cs.MiddleCircle>
                            <cs.MiddleImg src={section.galleryArticle.assets[1].asset.sourceUrl} />
                          </cs.MiddleCircle>
                        </cs.MiddleImageWrapper>
                        <cs.MiddleImageWrapper>
                          <cs.MiddleCircle margintop={60}>
                            <cs.MiddleImg src={section.galleryArticle.assets[2].asset.sourceUrl} width={40} />
                          </cs.MiddleCircle>
                          <cs.MiddleImage
                            src={section.galleryArticle.assets[3].asset.sourceUrl}
                            alt={section.galleryArticle.assets[3].asset.altText}
                          />
                        </cs.MiddleImageWrapper>
                      </cs.MiddleImageContainer>
                      <cs.MiddleContentContainer>
                        <cs.MiddleTitleMask>
                          <cs.MiddleTitle id="middleTitle" dangerouslySetInnerHTML={{__html: section.galleryArticle.title}}/>
                        </cs.MiddleTitleMask>
                        <TextMask>
                          <cs.MiddleText id="middleText" dangerouslySetInnerHTML={{__html: section.galleryArticle.content}}/>
                        </TextMask>
                      </cs.MiddleContentContainer>
                    </cs.MiddleContainer>
            case ('Community Members'):
              return <cs.MembersContainer key={section.communityMembers.title}>
                      <cs.MembersWrapper>
                        <cs.MembersHeaderWrapper>
                          <div>
                            <h1 dangerouslySetInnerHTML={{__html: section.communityMembers.title}}/>
                          </div>
                          <p dangerouslySetInnerHTML={{__html: section.communityMembers.content}}/>
                        </cs.MembersHeaderWrapper>
                        <cs.MembersPicturesWrapper>
                          <Members members={section.communityMembers.members}/>
                        </cs.MembersPicturesWrapper>
                      </cs.MembersWrapper>
                    </cs.MembersContainer>
            default:
              return <></>
          }
        })}
      </>
      :
      <>
        {error ?
          <ErrorSection/>
          :
          <></>
        }
      </>
      }
    </>
  )
}

export default CommunityPage

          