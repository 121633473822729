import styled from "styled-components"

export const ProgramContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`

export const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #333333;
  padding: 200px 60px 50px 60px;

  @media (max-width: 700px) {
    padding: 200px 20px 50px 20px;
  }

  @media (min-width: 1500px) {
    padding: 200px 150px 50px 150px;
  }

  @media (min-width: 2000px) {
    padding: 200px 300px 50px 300px;
  }
`

export const Title = styled.h1`
  width: 80%;
  color: white;
  font-size: 80px;
  text-align: left;
  line-height: 1.2;
  margin: 0 0 20px 0;
  font-size: 56px;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    font-size: 48px;
  }

  @media (max-width: 1000px) {
    font-size: 40px;
  }

  @media (max-width: 700px) {
    font-size: 36px;
    width: 100%;
  }
`

export const OverviewWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 60px;
  margin-top: 40px;

  @media (max-width: 700px) {
    padding: 20px;
  }

  @media (min-width: 1500px) {
    padding: 80px 150px;
  }

  @media (min-width: 2000px) {
    padding: 100px 300px;
  }
  
  p {
    font-weight: 300;
    max-width: 1000px;
    margin-bottom: 20px!important;
    font-size: 20px;
  
    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }
  
  h2 {
    font-size: 56px;
    margin-bottom: 40px!important;
  
    @media (max-width: 1200px) {
      font-size: 48px;
    }
  
    @media (max-width: 1000px) {
      font-size: 40px;
    }
  
    @media (max-width: 700px) {
      font-size: 28px;
    }
  
    @media (min-width: 2000px) {
      font-size: 64px;
    }
  }
  
  a {
    color: black;
    text-decoration: underline!important;
  }
  
  th {
    color: white;
    background: rgb(51, 51, 51);
  }
  
  table {
    margin-bottom: 40px!important;
  }
  
  li, ul {
    max-width: 1000px;
  }
  
  li, ul, td, tbody {
    font-weight: 300;
    margin-bottom: 10px;
    font-size: 20px!important;
  
    @media (max-width: 1200px) {
      font-size: 16px!important;
    }
  }
`

export const ProgramText = styled.p`
  max-width: 1000px;
  margin-bottom: 20px;
`