import styled from "styled-components"

export const AuthorWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem 2rem 1rem 2rem;
  margin-top: 1rem;
  background: #f6f6f6;

  @media (max-width: 700px) {
    padding: 40px 20px 10px 20px;
  }

  @media (min-width: 1500px) {
    padding: 80px 150px 40px 150px;
  }

  @media (min-width: 2000px) {
    padding: 100px 300px 50px 300px;
  }
`

export const AuthorRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: fit-content(40%) 1fr;
  grid-gap: 5rem;
  padding-bottom: 60px;
  margin-top: 60px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-gap: 40px;
  }
`

export const AuthorLeftCol = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`

export const AuthorTitle = styled.p`
  color: black;
  text-decoration: none;
  font-weight: 600;
  margin: 24px 0 0 0;
  font-size: 16px;

  @media (max-width: 1200px) {
    font-size: 14px;
  }

  @media (max-width: 800px) {
    font-size: 16px;
  }
`

export const AuthorRightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const AuthorName = styled.p`
  color: black;
  font-weight: 600;
  font-size: 24px;

  @media (max-width: 1200px) {
    font-size: 22px;
  }

  @media (max-width: 800px) {
    font-size: 24px;
  }
`

export const AuthorContainer = styled.div`  
  width: 150px;  

  @media (max-width: 550px) {
    width: 200px;
  }

`

export const AuthorImg = styled.img`
  width: 100%;
`

export const ProgramText = styled.p`
  font-size: 18px;
  max-width: 1000px;
  margin: 20px 0;

  @media (max-width: 1200px) {
    font-size: 16px;
  }
`

export const Reference = styled.a`
  font-size: inherit;
`

export const AuthorTitles = styled.p`
  font-size: 14px;

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 800px) {
    font-size: 12px;
  }
`

export const BlogSubheading = styled.h2`
  margin-top: 2rem;
  margin-bottom: 1rem;
`

export const BlogArticleButton = styled.button`
  width: fit-content;
  padding: 1rem 2rem;
  margin: 1rem 0;
  height: fit-content;
  background-color: #ffcb00;
  border: none;
  border-radius: 30px;
  font-weight: 400;
  font-size: inherit;
  text-decoration: none;
  color: #333333;
  box-shadow: 0px 0px 15px 0px rgba(255, 203, 0, 0.5);
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(94, 158, 214, 1);
  }

  @media (max-width: 700px) {
    box-sizing: border-box;
    margin: 1rem 1rem;
  }
`

export const AuthorSubTitles = styled(ProgramText)`
  margin: 0;
  font-size: inherit;
`

export const SubTitle = styled.p`
  max-width: 800px;
  color: white;
  font-size: 18px;
  font-weight: 300;
  width: 90%;
`