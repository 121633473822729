import styled from "styled-components"
import {
  TextMask,
  SlideTitle,
  SlideText,
} from "../../components/globalStyle"

export const SideContainer = styled.div`
  width: 100%;
  margin: 0;
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-areas: "content image";

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-gap: 40px 0;
    margin-bottom: 80px;
    grid-template-areas:
      "image"
      "content";
  }
`

export const SideImage = styled.img`
  width: 100%;
  grid-area: image;

  @media (max-width: 1000px) {
    margin: 0 auto;
    height: 450px;
    object-fit: cover;
  }

  @media (max-width: 700px) {
    height: auto;
  }
`

export const SideContentWrapper = styled.div`
  width: 100%;
  grid-area: content;
  padding: 0 100px 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media (max-width: 1200px) {
    padding: 0 80px 0 60px;
    justify-content: flex-end;
  }

  @media (max-width: 850px) {
    padding: 0 60px;
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 150px 0 300px;
  }
`

export const SideTitleMask = styled(TextMask)`
  margin-bottom: 40px;
  margin-top: 80px;

  @media (max-width: 1000px) {
    margin-top: 0;
  }
`

export const SideTitle = styled(SlideTitle)`
  position: relative;
  z-index: 1;
  width: 100%;
`

export const SideSmallTitleMask = styled(TextMask)`
  margin-bottom: 40px;
`

export const SideDescMask = styled(TextMask)`
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`

export const SideDesc = styled(SlideText)`
  width: 100%;
  position: relative;
`