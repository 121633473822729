import React from "react"
import { TextMask } from "../../components/globalStyle"
import {
  JobCard,
  JobContent,
  JobTitle,
  JobDescSection,
  JobDesc,
  JobRightSection,
  JobSubHeading,
  ApplicationButton,
  JobLineBreak,
  JobDetails
} from './jobListingStyle'

function JobListing ({ data }) {

  return (
    <>
      <JobCard>
        <JobContent>
          <JobTitle dangerouslySetInnerHTML={{__html: data.jobListings.title}}/>
          <JobDetails>
            ${data.jobListings.salaryRange} • {data.jobListings.positionType} • {data.jobListings.location}
          </JobDetails>
          <JobDetails>Application Deadline: {data.jobListings.applicationDeadline}</JobDetails>
          <JobDescSection>
            <JobSubHeading>About the Job</JobSubHeading>
            <JobDesc dangerouslySetInnerHTML={{__html: data.jobListings.description}}/>
            <JobLineBreak />
            <JobSubHeading>Benefits</JobSubHeading>
            <JobDesc dangerouslySetInnerHTML={{__html: data.jobListings.benefits}}/>
            <JobRightSection>
              <ApplicationButton target="_blank" href={data.jobListings.link}>
                Apply Now!
              </ApplicationButton>
            </JobRightSection>
          </JobDescSection>
        </JobContent>
      </JobCard>
    </>
  )

}

export default JobListing