import styled from "styled-components"

export const HeaderContainer = styled.div`
  background: ${props =>
    props.navbg === "true" ? "#FAFAFA" : "transparent"
  };
  transition: all 0.7s;
  width: 100%;
  padding: 20px 60px 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  pointer-events: none;

  @media (max-width: 700px) {
    padding: 10px 20px 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 40px 150px 20px 150px;
  }

  @media (min-width: 2000px) {
    padding: 40px 300px 20px 300px;
  }
`

export const HeaderLogoButton = styled.div`
  width: fit-content;
  height: fit-content;
  text-decoration: none;
  pointer-events: auto;
  cursor: pointer;
`

export const HeaderLogo = styled.img`
  width: 120px;

  @media (max-width: 500px) {
    width: 100px;
  }
`

export const HeaderButtonContainer = styled.div`
  width: 100px;
  height: 100px;
  position: relative;
  display: grid;
  place-items: center;

  @media (max-width: 700px) {
    width: 75px;
    height: 75px;
  }

  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
`

export const HeaderButton = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #ffcb00;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  position: absolute;
  z-index: 10;
  transition: transform 0.25s ease;
  outline: none;
  @media (max-width: 700px) {
    width: 75px;
    height: 75px;
  }
  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
  &:hover {
    transform: scale(1.05);
  }
`

export const HeaderButtonText = styled.span`
  color: #333;
  font-family: poppins, sans-serif;
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 12;
  @media (max-width: 700px) {
    font-size: 14px;
  }
`

export const NavigationContainer = styled.div`
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(51, 51, 51, 0);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  pointer-events: ${props => (props.navopen === 'true' ? "auto" : "none")};
`

export const NavigationBackArrow = styled.img`
  width: ${props => `${props.size}vw`};
  position: fixed;
  opacity: 0;
  z-index: 9;
  pointer-events: none;
  left: ${props => `calc(50vw - ${props.size / 2}vw)`};
  top: ${props => `calc(50vh - ${(props.size * 0.689) / 2}vw)`};

  @media (max-width: 1000px) {
    display: none;
  }
`

export const NavigationBackground = styled.div`
  width: 50vw;
  height: 50vw;
  border-radius: 50%;
  background: #333;
  opacity: 0;
  position: fixed;
  z-index: 8;
  top: calc(50vh - 25vw);
  left: calc(50vw - 25vw);
  pointer-events: none;

  @media (max-width: 1200px) and (min-height: 1300px) {
    width: 70vw;
    height: 70vw;

    top: calc(50vh - 35vw);
    left: calc(50vw - 35vw);
  }

  @media (max-width: 1000px) and (min-height: 800px) {
    width: 50vh;
    height: 50vh;

    top: calc(50vh - 25vh);
    left: calc(50vw - 25vh);
  }

  @media (max-width: 500px) and (min-height: 400px) {
    width: 50vh;
    height: 50vh;

    top: calc(50vh - 25vh);
    left: calc(50vw - 25vh);
  }
`

export const NavigationWrapper = styled.div`
  width: 90%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 70px;

  @media (max-width: 1000px) {
    width: calc(100vw - 120px);
  }

  @media (max-width: 800px) {
    justify-content: left;
  }

  @media (max-width: 700px) {
    width: calc(100vw - 40px);
  }
`

export const NavigationTextMask = styled.div`
  width: 25%;
  padding: 0;
  height: fit-content;
  overflow: hidden;
  margin: 75px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    margin: 0 0 50px 0;
    width: 50%;
    padding: 0;
    justify-content: left;

    &:last-child {
      margin: 0;
    }
  }
`

export const NavigationLink = styled.div`
  transform: translateY(100%);
  position: relative;
  display: flex;
  align-items: flex-start;
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    span {
      background: rgba(255, 203, 0, 0.5);
    }
  }
`

export const NavigationLinkText = styled.p`
  width: calc(100% - 35px);
  margin: 0;
  color: white;
  font-size: 32px;
  text-decoration: none;
  text-align: left;

  @media (max-width: 1450px) {
    font-size: 28px;
  }

  @media (max-width: 1250px) {
    font-size: 24px;
  }

  @media (max-width: 1100px) {
    font-size: 22px;
  }

  @media (max-width: 1000px) {
    font-size: 18px;
    width: calc(100% - 32px);
  }

  @media (max-width: 500px) {
    font-size: 16px;
  }
`

export const NavigationNumber = styled.span`
  width: 25px;
  height: 18px;
  background: rgba(155, 155, 155, 0.5);
  border-radius: 3px;
  display: grid;
  place-items: center;
  color: white;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1.4px;
  margin-top: 15px;
  margin-right: 10px;
  box-sizing: border-box;
  padding-top: 1px;
  transition: background 0.1s ease;

  @media (max-width: 1450px) {
    margin-top: 12px;
  }

  @media (max-width: 1250px) {
    font-size: 10px;
    margin-top: 8px;
  }

  @media (max-width: 1100px) {
    margin-top: 7px;
  }

  @media (max-width: 1000px) {
    margin-top: 6px;
    width: 22px;
    height: 16px;
  }

  @media (max-width: 500px) {
    margin-top: 4px;
  }
`