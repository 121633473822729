import styled from "styled-components"

export const ProgramContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
`

export const BannerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #333333;
  padding: 200px 60px 50px 60px;

  @media (max-width: 700px) {
    padding: 200px 20px 50px 20px;
  }

  @media (min-width: 1500px) {
    padding: 200px 150px 50px 150px;
  }

  @media (min-width: 2000px) {
    padding: 200px 300px 50px 300px;
  }
`

export const Title = styled.h1`
  width: 80%;
  color: white;
  font-size: 52px;
  text-align: left;
  line-height: 1.2;
  margin: 0 0 20px 0;

  @media (max-width: 900px) {
    font-size: 40px;
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 32px;
    width: 100%;
  }
`

export const OverviewWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 60px 60px 0 60px;
  font-size: 18px;

  @media (max-width: 700px) {
    padding: 20px 20px 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 80px 150px;
  }

  @media (min-width: 2000px) {
    padding: 100px 300px;
  }

  a {
    color: black;
    text-decoration: underline;
  }

  p {
    font-weight: 300;
    margin-bottom: 20px!important;
    font-size: 18px;
  
    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }
`

export const BlogRecs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`

export const BlogRecTitle = styled.h6`
  font-size: 32px;
  margin-bottom: 60px;
  padding: 0px 60px;
  margin: 60px 0px;

  @media (max-width: 1200px) {
    font-size: 28px;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }

  @media (min-width: 1500px) {
    padding: 0px 150px;
  }

  @media (min-width: 2000px) {
    padding: 0px 300px;
  }

  @media (max-width: 700px) {
    padding: 1rem;
    margin-bottom: 0;
  }
`

export const BlogLink = styled.div`
  :hover {
    cursor: pointer;
  }
`

export const BlogRecPreviewTitle = styled.h3`
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0 0 0;

  @media (max-width: 700px) {
    margin: 0 0 0 10px;
    font-size: 18px;
  }
  @media (max-width: 650px) {
    font-size: 14px;
  }
`

export const HeaderImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const HeaderImage = styled.img`
  height: 500px;
  align-self: center;
  @media (max-width: 1000px) {
    height: 400px;
  }
  @media (max-width: 800px) {
    height: 300px;
  }
`