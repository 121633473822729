import React, { useContext, useEffect, useState } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import { LoaderContainer, LoaderGif } from './loaderStyle'

import Loader from "../../images/loader.gif"

const LoaderComponent = () => {
  const {
    turnOffDisableScroll,
    completeLandingAnimation,
    turnOnDisableScroll,
  } = useContext(CilarContext)
  const [loaderVisible, setLoaderVisible] = useState(true)

  useEffect(() => {
    turnOnDisableScroll()
    setTimeout(() => {
      turnOffDisableScroll()
      setLoaderVisible(false)
      completeLandingAnimation()
    }, 2000)
  }, [])

  return (
    <LoaderContainer visible={loaderVisible.toString()}>
      <LoaderGif src={Loader}></LoaderGif>
    </LoaderContainer>
  )
}

export default LoaderComponent
