import React, { useState, useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import DropdownArrow from "../../images/dropdown-arrow.png"
import * as cs from "./contactStyle"
import { BASE_URL } from "../../helpers/wpHelper"
import axios from 'axios'

function Contact({ data }) {
  const [contactMessage, setContactMessage] = useState("empty")
  const [fName, setFName] = useState("")
  const [lName, setLName] = useState("")
  const [email, setEmail] = useState("")
  const [area, setArea] = useState("")
  const [comment, setComment] = useState("")
  const {
    textSlideUp,
    textFadeIn,
  } = useContext(CilarContext)

  useEffect(() => {
    textSlideUp(".contact")
    textFadeIn(".contactForm")
    // Add reCaptcha
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE}`
    document.body.appendChild(script)
  }, [])

  const handleFName = e => {
    setFName(e.target.value)
  }

  const handleLName = e => {
    setLName(e.target.value)
  }

  const handleEmail = e => {
    setEmail(e.target.value)
  }

  const handleArea = e => {
    setArea(e.target.value)
  }

  const handleComment = e => {
    setComment(e.target.value)
  }

  const handleContactSubmit = e => {
    e.preventDefault()

    window.grecaptcha.ready(_ => {
      window.grecaptcha
        .execute(`${process.env.REACT_APP_RECAPTCHA_SITE}`, { action: "submit" })
        .then(token => {
          let formData = new FormData()

          formData.set("first-name", fName)
          formData.set("last-name", lName)
          formData.set("email", email)
          formData.set("area-of-interest", area === "" ? "No area selected" : area)
          formData.set("comments", comment)
          formData.set("_wpcf7_recaptcha_response", token)

          axios.post(
            `${BASE_URL}wp-json/contact-form-7/v1/contact-forms/351/feedback`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data",
              },
            }
          )
          .then(res => {
            setContactMessage("Thank you for your submission!")
            // if (res.data.status === "mail_sent") {
            //   setContactMessage("Successfully submitted!")
            // } else {
            //   setContactMessage("An error occured! Please try again.")
            // }
            setFName("")
            setLName("")
            setEmail("")
            setArea("")
            setComment("")
          });
        })
    })
  }

  return (
    <cs.ContactContainer id="contact">
      <cs.ContactContentWrapper>
        <cs.ContactTitleMask>
          <cs.ContactTitle className="contact">{data.header.title}</cs.ContactTitle>
        </cs.ContactTitleMask>
        <cs.ContactDescMask>
          <cs.ContactDesc className="contact" dangerouslySetInnerHTML={{__html: data.header.content}} />
        </cs.ContactDescMask>
        <cs.ContactExtraWrapperMask>
          <cs.ContactExtraWrapper className="contact">
            <cs.ContactSubTitle>{data.contactDetails.title}</cs.ContactSubTitle>
            <cs.ContactAddress>
              {data.contactDetails.address}
            </cs.ContactAddress>
            <cs.ContactEmail href={`mailto:${data.contactDetails.email}`}>
            {data.contactDetails.email}
            </cs.ContactEmail>
          </cs.ContactExtraWrapper>
        </cs.ContactExtraWrapperMask>
      </cs.ContactContentWrapper>
      <cs.ContactForm onSubmit={handleContactSubmit} className="contactForm">
        <cs.ContactFormWrapper>
          <cs.ContactInputWrapper>
            <cs.ContactFormLabel htmlFor="fname">First name</cs.ContactFormLabel>
            <cs.ContactFormInput
              id="fname"
              name="fname"
              type="text"
              placeholder="First name"
              value={fName}
              required
              onChange={handleFName}
            />
          </cs.ContactInputWrapper>
          <cs.ContactInputWrapper>
            <cs.ContactFormLabel htmlFor="lname">Last name</cs.ContactFormLabel>
            <cs.ContactFormInput
              id="lname"
              name="lname"
              type="text"
              placeholder="Last name"
              value={lName}
              required
              onChange={handleLName}
            />
          </cs.ContactInputWrapper>
        </cs.ContactFormWrapper>
        <cs.ContactInputWrapper>
          <cs.ContactFormLabel htmlFor="email">Email Address</cs.ContactFormLabel>
          <cs.ContactFormInput
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            value={email}
            required
            onChange={handleEmail}
          />
        </cs.ContactInputWrapper>
        <cs.ContactInputWrapper>
          <cs.ContactFormLabel htmlFor="areaInterest">
            Area of interest
          </cs.ContactFormLabel>
          <cs.ContactFormSelectorArrow src={DropdownArrow} />
          <cs.ContactFormSelector
            id="areaInterest"
            name="areaInterest"
            value={area}
            onChange={handleArea}
          >
            <cs.Option value="" disabled defaultValue>
              Select an option
            </cs.Option>
            <cs.Option value="Mentor / Sponsor  as a Youth">
              Mentor / Sponsor as a Youth
            </cs.Option>
            <cs.Option value="Mentor / Sponsor as a Mid-level Manager or Director+">
              Mentor / Sponsor as a Mid-level Manager or Director+
            </cs.Option>
            <cs.Option value="Mentor / Sponsor as a C-Suite or aspiring Corporate Board Member">
              Mentor / Sponsor as a C-Suite or aspiring Corporate Board Member
            </cs.Option>
            <cs.Option value="Support one of our Pillars as a member or affiliate">
              Support one of our Pillars as a member or affiliate
            </cs.Option>
            <cs.Option value="Get more general information">
              Get more general information
            </cs.Option>
          </cs.ContactFormSelector>
        </cs.ContactInputWrapper>
        <cs.ContactInputWrapper>
          <cs.ContactFormLabel htmlFor="commentSection">
            Comments / More info
          </cs.ContactFormLabel>
          <cs.ContactFormInputLarge
            id="commentSection"
            name="commentSection"
            value={comment}
            onChange={handleComment}
            placeholder="Please leave a comment..."
          />
        </cs.ContactInputWrapper>
        <cs.ContactButton>
          Get in Contact!
        </cs.ContactButton>
        <cs.ContactMessage contactmessage={contactMessage}>
          {contactMessage}
        </cs.ContactMessage>
      </cs.ContactForm>
      <cs.ContactExtraWrapperMobileMask>
        <cs.ContactExtraWrapperMobile className="contact">
          <cs.ContactSubTitle>{data.contactDetails.title}</cs.ContactSubTitle>
          <cs.ContactAddress>{data.contactDetails.address}</cs.ContactAddress>
          <cs.ContactEmail href={`mailto:${data.contactDetails.email}`}>
            {data.contactDetails.email}
          </cs.ContactEmail>
        </cs.ContactExtraWrapperMobile>
      </cs.ContactExtraWrapperMobileMask>
    </cs.ContactContainer>
  )
}

export default Contact
