import styled from "styled-components"

export const TimelineWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center;
  @media (max-width: 1200px) {
    grid-template-columns: auto;
    align-items: auto;
    display: auto;
  }
`

export const TimelineCol = styled.div`
  grid-template-rows: fit-content(200px);
  @media (max-width: 1200px) {
    display: none;
  }
`

export const TimelineSection = styled.div`
  width: 100%;
  height: calc(((100vw) / 4) * 1.3);
  @media (max-width: 1200px) {
    height: calc(((100vw - 160px) / 5) * 1.5);
  }

  @media (max-width: 900px) {
    height: calc(((100vw - 160px) / 4) * 1.5);
  }

  @media (min-width: 1500px) {
    height: calc(((100vw - 340px) / 5) * 1.3);
  }

  @media (min-width: 2000px) {
    height: calc(((100vw - 640px) / 5) * 1.3);
  }
`

export const TimelineSectionBubble = styled(TimelineSection)`
  padding: 10px;
  justify-content: center;
  display: flex;
  position: relative;
  align-items: ${props => (props.align === "top" ? "flex-start" : "flex-end")};
`

export const BubbleImg = styled.img`
  width: 90%;
  height: auto;
  z-index: 0;
  transform: ${props => (props.backwards === "true" ? "rotate(180deg)" : "rotate()")};
  @media (max-wdith: 800px) {
    width: 100px;
    height: 100px;
  }
`

export const BubbleDate = styled.h4`
  font-size: ${props => (props.larger ? "40px" : "30px")};
  width: 75%;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: ${props => (props.align === "top" ? "25%" : "auto")};
  bottom: ${props => (props.align === "top" ? "auto" : "25%")};

  @media (min-width: 1500px) {
    top: ${props => (props.align === "top" ? "34%" : "auto")};
    bottom: ${props => (props.align === "top" ? "auto" : "34%")};
  }

  @media (max-width: 1500px) {
    top: ${props => (props.align === "top" ? "20%" : "auto")};
    bottom: ${props => (props.align === "top" ? "auto" : "20%")};
  }

  @media (max-width: 1200px) {
    font-size: ${props => (props.larger ? "30px" : "26px")};
    top: ${props => (props.align === "top" ? "30%" : "auto")};
    bottom: ${props => (props.align === "top" ? "auto" : "30%")};
  }

  @media (max-width: 900px) {
    font-size: ${props => (props.larger ? "45px" : "26px")};
    top: ${props => (props.align === "top" ? "40%" : "auto")};
    bottom: ${props => (props.align === "top" ? "auto" : "40%")};
  }

  @media (max-width: 700px) {
    font-size: ${props => (props.larger ? "32px" : "26px")};
    top: ${props => (props.align === "top" ? "40%" : "auto")};
    bottom: ${props => (props.align === "top" ? "auto" : "40%")};
  }

  @media (max-width: 400px) {
    font-size: ${props => (props.larger ? "28px" : "26px")};
    top: ${props => (props.align === "top" ? "45%" : "auto")};
    bottom: ${props => (props.align === "top" ? "auto" : "45%")};
  }
`

export const TimelineSectionText = styled(TimelineSection)`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5%;
  justify-content: ${props =>
    props.align === "top" ? "flex-end" : "flex-start"};
`

export const TimelineChevron = styled.img`
  width: 20px;
  transform: ${props => (props.backwards === "true" ? "rotate(180deg)" : "rotate()")};
  margin: 10px 0;

  @media(max-width: 1200px) {
    transform: ${props => (props.backwards === "true" ? "rotate(270deg)" : "rotate(90deg)")};
    width: auto;
    height: 15px;
    // margin: ${props => (props.backwards === "true" ? "" : "rotate(90deg)")};
  }
  @media(max-width: 500px) {
    height: 10px;
  }
`

export const TimelineText = styled.p`
  @media (max-width: 1200px) {
    margin: 0px 1rem;
  }
`

export const TimelineRod = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`

// Vertical Timeline Styling
export const MobileTimeline = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media (min-width: 1201px) {
    display: none;
  }
`

export const MobileTimelineColLeft = styled.div`
  float: left;
  width: 45%;
`

export const MobileSectionText = styled.div`
  display: flex;
  justify-content: ${props => (props.align === "left" ? "end" : "start")};
  align-items: center;
  width: 100%;
`

export const MobileTimelineColRight = styled.div`
  float: right;
  width: 45%;
  margin-left: 2rem;
  @media (max-width: 500px) {
    margin-left: 0;
  }
`

export const MobileTimelineSectionBubble = styled.div`
  margin: ${props => (props.align === "left" ? "1rem 0 1rem 0" : "1rem 0 1rem 0")};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const MobileBubbleDate = styled.h4`
  margin: ${props => (props.align === "left" ? "0 0 0 2rem" : "0 2rem 0 0")};
  position: absolute;
  text-align: center;
  z-index: 1;
  font-size: 24px;

  @media (max-width: 500px) {
    font-size: 18px;
    margin: ${props => (props.align === "left" ? "0 0 0 1rem" : "0 1rem 0 0")};
  }
`

export const MobileBubbleImg = styled.img`
  width: 80%;
  z-index: 0;
  transform: ${props => (props.backwards === "true" ? "rotate(270deg)" : "rotate(90deg)")};
`

export const MobileTimelineColCenter = styled.div`
  flex: 1;
`

export const MobileLine = styled.div`
  border-left: 10px solid black;
  height: 100%;
  position: absolute;
  left: 50%;
  z-index: 1;
  top: 0;
  bottom: 0;
`

export const MobileCircle = styled.div`
  width: 50px;
  height: 50px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: black;
  border: 10px solid #ffcb00;
  z-index: 3;
  position: relative;
  margin-left: 2.5rem;

  @media (max-width: 500px) {
    margin-left: 1.5rem;
    margin-right: 1rem;
    width: 25px;
    height: 25px;
    border: 5px solid #ffcb00;
  }
`