import React, { useEffect } from "react"
import { useLocation } from "react-router-dom";
import {
  ProgramContainer,
  BannerContainer,
  Title,
  OverviewWrapper,
} from '../NewsArticle/newsArticleStyle'
import {
  AuthorWrapper,
  AuthorRow,
  AuthorLeftCol,
  AuthorRightCol,
  AuthorContainer,
  AuthorImg,
  AuthorName,
  ProgramText,
  Reference,
  AuthorTitles,
  BlogSubheading,
  BlogArticleButton,
  AuthorSubTitles,
  SubTitle
} from './blogArticleStyle'
import { gql, useQuery } from '@apollo/client'
import ErrorSection from "../../components/Error/error";

const GET_ARTICLE = gql`
query GetArticle($articleTitle: String) {
  posts(where: {title: $articleTitle}) {
    nodes {
      blogArticle {
        title
        date
        content
        link
        asset {
          altText
          sourceUrl
        }
        references {
          linkText
          link
        }
        authorInfo {
          name
          asset {
            altText
            sourceUrl
          }
          description
          titles {
            title
          }
          organization
          cilarAffiliation
        }
      }
    }
  }
}
`

function BlogArticle() {
  const { state } = useLocation();
  const { articleTitle } = state;
  const { loading, error, data } = useQuery(GET_ARTICLE, {
    variables: { "articleTitle": articleTitle }
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {!loading && !error ?
        <ProgramContainer>
          <BannerContainer>
            <Title dangerouslySetInnerHTML={{__html: data.posts.nodes[0].blogArticle.title}}/>
            <SubTitle>{data.posts.nodes[0].blogArticle.authorInfo.name}</SubTitle>
            <SubTitle>{data.posts.nodes[0].blogArticle.date}</SubTitle>
          </BannerContainer>
          <OverviewWrapper>
            <div dangerouslySetInnerHTML={{__html: data.posts.nodes[0].blogArticle.content.replaceAll('<br />', '')}}/>
            {data.posts.nodes[0].blogArticle.link ?
              <BlogArticleButton href={data.posts.nodes[0].blogArticle.link} target="_blank">More Information → </BlogArticleButton>
              :
              <></>
            }
            {data.posts.nodes[0].blogArticle.references ?
              <>
                <BlogSubheading>References</BlogSubheading>
                {data.posts.nodes[0].blogArticle.references.map((ref, i) => {
                  return <>
                          <Reference key={`Reference-${i}`} href={ref.link} target="_blank" dangerouslySetInnerHTML={{__html: ref.linkText}}/>
                          <br/>
                         </>
                })}
              </>
              :
              <></>
            }
          </OverviewWrapper>

          <AuthorWrapper>
            <AuthorRow>
              <AuthorLeftCol>
                <AuthorContainer>
                  <AuthorImg src={data.posts.nodes[0].blogArticle.authorInfo.asset.sourceUrl} alt={data.posts.nodes[0].blogArticle.authorInfo.asset.altText} />
                  {data.posts.nodes[0].blogArticle.authorInfo.titles.map(t => {
                    return <AuthorTitles dangerouslySetInnerHTML={{__html: t.title}}/>
                  })}
                </AuthorContainer>
              </AuthorLeftCol>
              <AuthorRightCol>
                <AuthorName dangerouslySetInnerHTML={{__html: data.posts.nodes[0].blogArticle.authorInfo.name}}/>
                {data.posts.nodes[0].blogArticle.authorInfo.organization ?
                  <AuthorSubTitles>{`Organization: ${data.posts.nodes[0].blogArticle.authorInfo.organization}`}</AuthorSubTitles>
                  :
                  <></>
                }
                {data.posts.nodes[0].blogArticle.authorInfo.cilarAffiliation ?
                  <AuthorSubTitles>{`CILAR Affiliation: ${data.posts.nodes[0].blogArticle.authorInfo.cilarAffiliation}`}</AuthorSubTitles>
                  :
                  <></>
                }
                <ProgramText dangerouslySetInnerHTML={{__html: data.posts.nodes[0].blogArticle.authorInfo.description}}/>
              </AuthorRightCol>
            </AuthorRow>
          </AuthorWrapper>
        </ProgramContainer>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default BlogArticle
