import styled from "styled-components"
import {
  SlideText,
  SlideSubTitle,
} from "../../components/globalStyle"

export const JobCard = styled.div`
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 60px;

  @media (max-width: 700px) {
    padding: 60px;
  }

  @media (min-width: 1500px) {
    padding: 150px 150px 0px 150px;
  }
  
`

export const JobContent = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 60px 60px 20px 60px;
  background: #f4f4f4;

  @media (max-width: 700px) {
    padding: 20px 20px 10px 20px;
  }
`

export const JobTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    font-size: 36px;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }

  @media (min-width: 2000px) {
    font-size: 56px;
  }
`

export const JobDescSection = styled.div`
  width: 100%;
  margin-top: 20px;

  @media (max-width: 1000px) {
    margin-top: 20px;
  }
`

export const JobDesc = styled(SlideText)`
  transform: translateY(0);
`

export const JobRightSection = styled.div`
  width: 100%;
  display: flex;
  justify-content:center;
`

export const JobSubHeading = styled.h4`
  font-size: 22px;
  margin-bottom: 16px;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (min-width: 2000px) {
    font-size: 22px;
  }
`

export const JobDetails = styled.h6`
font-size: 18px;
margin-bottom: 8px;

@media (max-width: 1200px) {
  font-size: 16px;
}

@media (min-width: 2000px) {
  font-size: 18px;
}
`

export const ApplicationButton = styled.button`
  width: 400px;
  max-width: 100%;
  height: fit-content;
  display: grid;
  background-color: #ffcb00;
  border: none;
  border-radius: 30px;
  font-weight: 400;
  font-size: 24px;
  text-decoration: none;
  color: #333333;
  box-shadow: 0px 0px 15px 0px rgba(255, 203, 0, 0.5);
  cursor: pointer;
  margin-top: 60px;
  margin-bottom: 40px;
  padding: 15px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(94, 158, 214, 1);
  }
  @media (max-width: 1400px) and (max-height: 900px) {
    font-size: 20px;
  }

  @media (max-width: 700px) {
    width: 100%;
    box-sizing: border-box;
    font-size: inherit;
  }
`

export const JobLineBreak = styled.hr`
  margin: 20px 0;
`;