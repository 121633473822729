import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import styled from "styled-components"
import {
  SideTitle,
  SideDesc,
} from './SplitLayoutStyle'
import { TextMask } from "../../components/globalStyle"

const ProcessContainer = styled.div`
  width: 100%;
  margin: 120px 0;
  height: 52.775vw;
  position: relative;
  z-index: 2;

  @media (max-width: 850px) {
    margin: 100px 0 80px 0;
  }

  @media (max-width: 700px) {
    height: 70vw;
  }

  @media (max-width: 500px) {
    height: 120vw;
  }
`

const ProcessImage = styled.img`
  width: 100%;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 700px) {
    height: 70vw;
    object-fit: cover;
  }

  @media (max-width: 500px) {
    height: 120vw;
  }
`

const ProcessGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0) 0%,
    rgba(51, 51, 51, 0.7) 70%,
    rgba(51, 51, 51, 0.5) 100%
  );
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 60px;

  @media (max-width: 850px) {
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    background: linear-gradient(
      180deg,
      rgba(51, 51, 51, 0) 0%,
      rgba(51, 51, 51, 0.7) 60%,
      rgba(51, 51, 51, 0.5) 100%
    );
  }

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 300px;
  }
`

const ProcessTitleMask = styled(TextMask)`
  margin-bottom: 150px;

  @media (max-width: 1100px) {
    margin-bottom: 100px;
  }

  @media (max-width: 850px) {
    margin-bottom: 30px;
  }
`

const ProcessTitle = styled(SideTitle)`
  color: white;
  position: relative;
  z-index: 0;
`

const ProcessDescMask = styled(TextMask)`
  margin-bottom: 150px;

  @media (max-width: 1100px) {
    margin-bottom: 110px;
  }

  @media (max-width: 1000px) {
    width: 400px;
  }

  @media (max-width: 850px) {
    margin-bottom: 60px;
    width: 100%;
    max-width: 600px;
  }

  @media (min-width: 1500px) {
    width: 600px;
    margin-bottom: 165px;
  }
`

const ProcessDesc = styled(SideDesc)`
  color: white;
  width: 500px;
  position: relative;
  p {
    color: white;
  }
  a {
    color: white;
    text-decoration: underline;
  }

  @media (max-width: 1000px) {
    width: 400px;
  }

  @media (max-width: 850px) {
    width: 100%;
    max-width: 600px;
  }

  @media (min-width: 1500px) {
    width: 600px;
  }
`

function TextBottomSection({ data }) {
  const { textSlideUp } = useContext(CilarContext)

  useEffect(() => {
    textSlideUp(".belief")
  }, [])
  
  return (
    <>
      <ProcessContainer>
        <ProcessGradient>
          <ProcessTitleMask>
            <ProcessTitle className="belief"dangerouslySetInnerHTML={{ __html: data.title }}/>
          </ProcessTitleMask>
          <ProcessDescMask>
            <ProcessDesc className="belief" dangerouslySetInnerHTML={{ __html: data.content }} />
          </ProcessDescMask>
        </ProcessGradient>
        {data.asset ?
          <ProcessImage
            src={data.asset.sourceUrl}
            alt={data.asset.altText}
          />
          :
          <></>
        }
      </ProcessContainer>
    </>
  )
}

export default TextBottomSection