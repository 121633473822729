import React from "react"
import * as timeline from "./timelineStyle"
import Rod from "../../images/about/rod.png"
import Bubble from "../../images/about/bubble.png"
import Chevron from "../../images/about/chevron.png"

const Timeline = ({ data }) => {
  return (
    <timeline.TimelineWrapper>
      {data.timelineBubble.map((bubble, index) => {
        if (index % 2 === 0)
          return  <div key={bubble.date.toString()}>
                    <timeline.TimelineCol>
                      <timeline.TimelineSectionBubble>
                        <timeline.BubbleDate dangerouslySetInnerHTML={{__html: bubble.date.replace(' ', "<br/>")}}/>
                        <timeline.BubbleImg src={Bubble} />
                      </timeline.TimelineSectionBubble>
                      <timeline.TimelineRod src={Rod} />
                      <timeline.TimelineSectionText> 
                        <timeline.TimelineChevron src={Chevron} />
                        <timeline.TimelineText dangerouslySetInnerHTML={{__html: bubble.content}}/>
                      </timeline.TimelineSectionText>
                    </timeline.TimelineCol>
                    {/* Vertical timeline */}
                    <timeline.MobileTimeline>
                      <timeline.MobileTimelineColLeft>
                        <timeline.MobileTimelineSectionBubble>
                          <timeline.MobileBubbleDate dangerouslySetInnerHTML={{__html: bubble.date.replace(' ', "<br/>")}}/>
                          <timeline.MobileBubbleImg src={Bubble} backwards={"true"}/>
                        </timeline.MobileTimelineSectionBubble>
                      </timeline.MobileTimelineColLeft>
                      <timeline.MobileTimelineColCenter>
                        <timeline.MobileCircle margin="left"/>
                        <timeline.MobileLine/>                        
                      </timeline.MobileTimelineColCenter>
                      <timeline.MobileTimelineColRight>
                        <timeline.MobileSectionText>
                          <timeline.TimelineChevron src={Chevron} backwards={"true"}/>
                          <timeline.TimelineText dangerouslySetInnerHTML={{__html: bubble.content}}/>
                        </timeline.MobileSectionText>
                      </timeline.MobileTimelineColRight>
                    </timeline.MobileTimeline>
                  </div>

        else 
          return  <div key={bubble.date.toString()}>
                    <timeline.TimelineCol>
                      <timeline.TimelineSectionText align="top">
                        <timeline.TimelineText dangerouslySetInnerHTML={{__html: bubble.content}}/>
                        <timeline.TimelineChevron src={Chevron} backwards={"true"} />
                      </timeline.TimelineSectionText>
                      <timeline.TimelineRod src={Rod} />
                      <timeline.TimelineSectionBubble align="top">
                        <timeline.BubbleDate align="top" dangerouslySetInnerHTML={{__html: bubble.date.replace(' ', "<br/>")}}/>
                        <timeline.BubbleImg src={Bubble} backwards={"true"} />
                      </timeline.TimelineSectionBubble>
                    </timeline.TimelineCol>
                    {/* Vertical timeline */}
                    <timeline.MobileTimeline>
                      <timeline.MobileTimelineColLeft>
                        <timeline.MobileSectionText align="left">
                          <timeline.TimelineText dangerouslySetInnerHTML={{__html: bubble.content}}/>
                          <timeline.TimelineChevron src={Chevron}/>
                        </timeline.MobileSectionText>
                      </timeline.MobileTimelineColLeft>
                      <timeline.MobileTimelineColCenter>
                        <timeline.MobileCircle/>
                        <timeline.MobileLine/>                        
                      </timeline.MobileTimelineColCenter>
                      <timeline.MobileTimelineColRight>
                        <timeline.MobileTimelineSectionBubble align="left">
                          <timeline.MobileBubbleDate align="left" dangerouslySetInnerHTML={{__html: bubble.date.replace(' ', "<br/>")}}/>
                          <timeline.MobileBubbleImg src={Bubble} />
                        </timeline.MobileTimelineSectionBubble>
                      </timeline.MobileTimelineColRight>
                      
                    </timeline.MobileTimeline>
                  </div>
      })}
    </timeline.TimelineWrapper>
  )
}

export default Timeline
