import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import {
  SideContainer,
  SideContentWrapper,
  SideTitleMask,
  SideLongTitle,
  SideDescMask,
  SideDesc,
  SideImage,
} from './SplitLayoutStyle'

function TextLeftSection({ data }) {
  const { textSlideUp } = useContext(CilarContext)

  useEffect(() => {
    textSlideUp("#innovationTitle")
    textSlideUp(".innovationDesc")
  }, [])
  
  return (
    <>
      <SideContainer imageside="right">
        <SideContentWrapper imageside="right">
          <SideTitleMask>
            <SideLongTitle id="innovationTitle" dangerouslySetInnerHTML={{ __html: data.title }} />
          </SideTitleMask>
          <SideDescMask>
            <SideDesc className="innovationDesc" dangerouslySetInnerHTML={{ __html: data.content }} />
          </SideDescMask>
        </SideContentWrapper>
        <SideImage
          src={data.asset.sourceUrl}
          alt={data.asset.altText}
        />
      </SideContainer>
    </>
  )

}

export default TextLeftSection