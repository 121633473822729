import React, { useEffect, useState } from "react"
import { 
  EventContainer,
  EventCard,
  EventDate,
  EventMonth,
  EventDay,
  EventContent,
  EventImg,
  EventTitle,
  EventHeading,
  EventChip,
  EventButton,
  MonthSeparator,
  EventTabs,
  TabNav,
  Tab,
  TabContent,
} from "./eventsStyle";

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

// Helper to get short form of month name
const getMonthShort = (month) => {
  if (month === 'September') {
    return 'SEPT';
  } else {
    return month.toUpperCase().slice(0, 3);
  }
};

// Layout of event card
const Card = ({ e }) => {
  return (
    <EventCard>
      <EventDate>
        <EventMonth dangerouslySetInnerHTML={{__html: getMonthShort(e.event.eventDates.startDate.split(' ')[0])}}/>
        <EventDay dangerouslySetInnerHTML={{__html: e.event.eventDates.startDate.split(' ')[1].slice(0, -1)}}/>
      </EventDate>
      <EventContent>
        <EventHeading>
          <p>
            {e.event.eventDates.startDate} @ {e.event.eventDates.startTime} - 
            {e.event.eventDates.endDate ? 
              ` ${e.event.eventDates.endDate} @ ${e.event.eventDates.endTime}` 
              : 
              ` ${e.event.eventDates.endTime}`
            }
          </p>
          <EventTitle>{e.event.title}</EventTitle>
          {e.event.location ?
            <p>{`${e.event.location} • ${e.event.format}`}</p>
            :
            <p>{e.event.format}</p>
          }
          {e.event.provisions ?
            <EventChip>{e.event.provisions}</EventChip>
            :
            <></>
          }
        </EventHeading>
        <div>
          <div dangerouslySetInnerHTML={{__html: e.event.description}}/>
          {e.event.link ? <EventButton href={e.event.link} target="_blank">Register → </EventButton> : <></>}
        </div>
      </EventContent>
      <EventImg src={e.event.asset.sourceUrl} alt={e.event.asset.altText}/>
    </EventCard>
  );
};

function Events({ data }) {
  const [activeTab, setActiveTab] = useState("tab1");
  const [ events, setEvents ] = useState([]);
  const [ pastEvents, setPastEvents ] = useState([]);
  const [ showMonthHeading, setShowMonthHeading ] = useState([]);
  const [ showYearHeading, setShowYearHeading ] = useState([]);
  const curDate = new Date();

  useEffect(() => {
    if (data) {
      var unsorted = data.slice();
      var sorted = unsorted.sort((a, b) => new Date(a.event.eventDates.startDate) - new Date(b.event.eventDates.startDate)); // sort events by date
      var sortedCopy = [...sorted];
      var curMonth = months[0];
      var showMonth = [], prevEvents = [], showYear = [];
      var curYear = '';

      for (var s in sorted) {
        if (curDate - new Date(sorted[s].event.eventDates.startDate) > 0) {
          if (curYear === sorted[s].event.eventDates.startDate.split(' ')[2]) {
            showYear.push(false);
          } else {
            curYear = sorted[s].event.eventDates.startDate.split(' ')[2];
            showYear.push(true);
          }
          prevEvents.push(sorted[s]);
          sortedCopy = sortedCopy.filter(item => item !== sorted[s]);
        } else {
          if (sorted[s].event.eventDates.startDate.split(' ')[0] != curMonth) {
            showMonth.push(true);
            curMonth = months[months.indexOf(sorted[s].event.eventDates.startDate.split(' ')[0])];
          } else {
            showMonth.push(false);
          }
        }
      }
      setEvents(sortedCopy);
      setPastEvents(prevEvents);
      setShowMonthHeading(showMonth);
      setShowYearHeading(showYear);
    }
  }, [])

  return (
    <>
      {events && showMonthHeading ?
      <>
        <EventTabs>
          <TabNav>
            <Tab activetab={(activeTab === "tab1").toString()} onClick={() => {setActiveTab("tab1")}}>Current Events</Tab>
            <Tab activetab={(activeTab === "tab2").toString()}  onClick={() => {setActiveTab("tab2")}}>Previous Events</Tab>
          </TabNav>
        </EventTabs>
        {activeTab === "tab1" ?
          <TabContent>
            {events.length > 0 ?
              <>
                {events.map((e, index) => {
                  return <EventContainer key={e.title}>
                          {showMonthHeading[index] ? 
                            <MonthSeparator>
                              <h2>{e.event.eventDates.startDate.split(' ')[0]} {e.event.eventDates.startDate.split(' ')[2]}</h2>
                              <hr/>
                            </MonthSeparator>
                            :
                            <></>
                          }
                          <Card e={e}/>
                        </EventContainer>
                })}
              </>
              :
              <EventContainer>
                <MonthSeparator>
                  <h2>No current events available.</h2>
                </MonthSeparator>
              </EventContainer>
            }
          </TabContent>
          :
          <TabContent>
            {pastEvents.length > 0 ?
              <>
                {pastEvents.map((e, index) => {
                  return <EventContainer key={e.title}>
                          {showYearHeading[index] ? 
                            <MonthSeparator>
                              <h2>{e.event.eventDates.startDate.split(' ')[2]}</h2>
                              <hr/>
                            </MonthSeparator>
                            :
                            <></>
                          }
                          <Card e={e}/>
                        </EventContainer>
                })}
              </>
              :
              <EventContainer>
                <MonthSeparator>
                  <h2>No past events available.</h2>
                </MonthSeparator>
              </EventContainer>
            }
          </TabContent>
        }
        
      </>
      :
      <></>
      }
    </>
  )
}

export default Events