import styled from "styled-components"
import { TextMask, SlideTitle } from "../../components/globalStyle"

export const LandingContainer = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: relative;

  @media (max-width: 500px) {
    height: 95vh;
  }
`

export const LandingBackground = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 800px) {
    display: none;
  }

  @media (max-width: 500px) {
    height: 95vh;
  }
`

export const LandingBackgroundMobile = styled(LandingBackground)`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
`

export const LandingGradient = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(51, 51, 51, 0.2) 0%,
    rgba(51, 51, 51, 0.7) 40%,
    rgba(51, 51, 51, 0.5) 70%,
    rgba(51, 51, 51, 0.2) 80%
  );
  display: flex;
  align-items: center;
  padding: 0 60px;

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (max-width: 500px) {
    height: 95vh;
  }

  @media (min-width: 1500px) {
    padding: 0 150px;
  }

  @media (min-width: 2000px) {
    padding: 0 300px;
  }
`

export const LandingTitleMask = styled(TextMask)`
  width: calc(100vw - 120px);
  position: relative;

  @media (max-width: 700px) {
    width: calc(100vw - 40px);
    margin-bottom: 10%;
  }

  @media (min-width: 1500px) {
    width: calc(100vw - 300px);
  }

  @media (min-width: 2000px) {
    width: calc(100vw - 600px);
  }
`

export const LandingTitle = styled(SlideTitle)`
  font-size: 100px;
  color: white;
  position: relative;
  z-index: 4;
  width: 100%;

  @media (max-width: 1200px) {
    font-size: 80px;
  }

  @media (max-width: 850px) {
    font-size: 58px;
  }

  @media (max-width: 500px) {
    font-size: 42px;
  }

  @media (min-width: 1500px) {
    width: calc(100% - 300px);
  }

  @media (min-width: 1800px) {
    font-size: 130px;
  }

  @media (min-width: 2200px) {
    font-size: 150px;
    width: calc(100% - 600px);
  }
`

export const LandingArrow = styled.img`
  position: absolute;
  z-index: 3;
  opacity: 0;
`

export const LandingArrowSmall = styled(LandingArrow)`
  width: 100px;
  right: 60px;
  bottom: 60px;

  @media (max-width: 1200px) {
    width: 100px;
  }

  @media (max-width: 1000px) {
    width: 75px;
  }

  @media (max-width: 700px) {
    bottom: 30px;
    right: 20px;
  }

  @media (max-width: 500px) {
    width: 50px;
    bottom: calc(30px + 5%);
  }

  @media (min-width: 1500px) {
    bottom: 120px;
    right: 150px;
  }

  @media (min-width: 2000px) {
    right: 300px;
  }
`

export const LandingSubtitle = styled.p`
  font-family: poppins;
  font-weight: 300;
  color: white;
  margin: 30px 0 0 0;
  font-size: 24px;
  position: relative;
  z-index: 4;
  width: 100%;
  transform: translateY(100%);
  max-width: 550px;

  @media (max-width: 800px) {
    margin: 10px 0 0 0;
    font-size: 18px;
  }
`