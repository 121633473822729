import React, { useState, useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import {
  FeaturedContainer,
  HeadingContainer,
  PageTitle,
  BlogDescription,
  BlogContainer,
  BlogGrid,
  BlogCard,
  BlogPreviewImage,
  Title,
  BlogContentPreview,
  PageCounterContainer,
  PageCounter,
  PageButton,
  BlogLink,
  BlogPreviewText,
  BlogContentPreviewMobile
} from '../News/newsStyle'
import { SubheadingText, Author, Date, } from './blogStyle'
import { gql, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import ErrorSection from "../../components/Error/error"

const GET_BLOG = gql`
query GetBlog {
  pages(where: {title: "Blog"}) {
    nodes {
      newsAndUpdates {
        title
      }
    }
  }
  posts(where: {categoryName: "Blog"}) {
    nodes {
      title
      blogArticle {
        title
        slug
        date
        preview
        asset {
          altText
          sourceUrl
        }
        authorInfo {
          name
        }
      }
    }
  }
}
`;

function BlogPage() {
  const { initialLandingAnimationCompleted, textSlideUp } = useContext(CilarContext);
  var pages
  const { loading, error, data } = useQuery(GET_BLOG);
  const navigate = useNavigate()
  const [currPage, setCurrPage] = useState(1)

  if (data) pages = Math.floor(data.posts.nodes.length / 9 + 1)

  const NextPage = () => {
    const currentIndex = currPage
    if (currentIndex !== pages) {
      setCurrPage(currentIndex + 1)
    }
  }

  const PrevPage = () => {
    const currentIndex = currPage
    if (currentIndex !== 1) {
      setCurrPage(currentIndex - 1)
    }
  }

  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        textSlideUp("#newsTitle")
      }, 2000)
    } else {
      setTimeout(() => {
        textSlideUp("#newsTitle")
      }, 1000)
    }
  }, [])

  return (
    <>
      {!loading && !error ?
        <>
          <FeaturedContainer>
            <HeadingContainer>
              <PageTitle id="newsTitle" dangerouslySetInnerHTML={{__html: data.pages.nodes[0].newsAndUpdates.title}}/>
              <BlogDescription>&nbsp;</BlogDescription>
            </HeadingContainer>
          </FeaturedContainer>
          <BlogContainer>
            <BlogGrid>
              {data.posts.nodes.map((post, index) => {
                const maxIndex = currPage * 9 - 1
                const minIndex = maxIndex + 1 - 9

                if (index >= minIndex && index <= maxIndex) {
                  return (
                    <BlogLink key={post.blogArticle.title} onClick={() => {navigate(`/blog/${post.blogArticle.slug}`, {state: {articleTitle: post.title}})}}>
                      <BlogCard>
                        <BlogPreviewImage src={post.blogArticle.asset.sourceUrl} alt={post.blogArticle.asset.altText}/>
                        <BlogPreviewText>
                          <Title>{post.blogArticle.title}</Title>
                          <SubheadingText>
                            <Author dangerouslySetInnerHTML={{__html: post.blogArticle.authorInfo.name}}/>
                            <Date dangerouslySetInnerHTML={{__html: post.blogArticle.date}}/>
                          </SubheadingText>
                          <BlogContentPreview>
                            {post.blogArticle.preview.substring(0, 150).trim() + "..."}
                          </BlogContentPreview>
                          <BlogContentPreviewMobile>
                            {post.blogArticle.preview.substring(0, 100).trim() + "..."}
                          </BlogContentPreviewMobile>
                        </BlogPreviewText>
                      </BlogCard>
                    </BlogLink>
                  )
                } else {
                  return <></>
                }
              })}
            </BlogGrid>
          </BlogContainer>
          <PageCounterContainer display={pages > 1 ? "" : "none"}>
            <PageCounter>
              <PageButton onClick={PrevPage}> &#60; </PageButton>
              <p>
                {currPage}/{pages}
              </p>
              <PageButton onClick={NextPage}> &#62; </PageButton>
            </PageCounter>
          </PageCounterContainer>
        </>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default BlogPage
