import React, { useEffect, useContext } from "react"
import { CilarContext } from "../../contexts/cilarContext"
import Contact from "../../components/Contact/contact"
import { TextMask } from "../../components/globalStyle"
import {
  ContactBanner,
  ContactBannerContentWrapper,
  ContactBannerTitle,
  ContactBannerImage,
} from "./contactStyle"
import { gql, useQuery } from '@apollo/client'
import ErrorSection from "../../components/Error/error"

const GET_CONTACT = gql`
query GetCommunity {
  allContact {
    nodes {
      title
      categories(first: 10) {
        edges {
          node {
            name
          }
        }
      }
      contactHeading {
        title
        asset {
          altText
          sourceUrl
        }
      }
      contact {
        header {
          title
          content
        }
        contactDetails {
          address
          email
          title
        }
      }
    }
  }
}
`;

function ContactPage() {
  const {
    initialLandingAnimationCompleted,
    textSlideUp,
  } = useContext(CilarContext)

  const { loading, error, data } = useQuery(GET_CONTACT);

  useEffect(() => {
    if (!initialLandingAnimationCompleted) {
      setTimeout(() => {
        textSlideUp("#contactBannerTitle")
      }, 2000)
    } else {
      setTimeout(() => {
        textSlideUp("#contactBannerTitle")
      }, 1000)
    }
  }, [])

  return (
    <>
      {!loading && !error ?
      <>
        {data.allContact.nodes.map(section => {
          switch(section.categories.edges[0].node.name) {
            case ('Contact Heading'):
              return <ContactBanner key={section.title}>
                        <ContactBannerContentWrapper>
                          <TextMask>
                            <ContactBannerTitle id="contactBannerTitle" dangerouslySetInnerHTML={{__html: section.contactHeading.title}}/>
                          </TextMask>
                        </ContactBannerContentWrapper>
                        <ContactBannerImage
                          src={section.contactHeading.asset.sourceUrl}
                          alt={section.contactHeading.asset.altText}
                        />
                      </ContactBanner>
            case ('Contact Form Content'):
              return <Contact key={section.title} data={section.contact}/>
            default:
              return <></>
          }
        })}
      </>
      :
      <>
        {error ?
          <ErrorSection/>
          :
          <></>
        }
      </>
      }
    </>
  )
}

export default ContactPage
