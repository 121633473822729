import styled from "styled-components"

export const FooterContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 60px 60px 30px 60px;
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    padding: 60px 20px 30px 20px;
  }

  @media (min-width: 1500px) {
    padding: 60px 150px 30px 150px;
  }

  @media (min-width: 2000px) {
    padding: 60px 300px 30px 300px;
  }
`

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  @media (max-width: 1000px) {
    margin: 0;
  }
`

export const FooterLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`

export const FooterLogo = styled.img`
  width: 120px;
  margin: 0;

  @media (max-width: 500px) {
    width: 100px;
  }
`

export const FooterCopyright = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`

export const CopyrightText = styled.p`
  font-size: 14px;
  font-weight: 300;
  color: #9b9b9b;
  margin: 0;

  a {
    color: black;
  }
`;

export const FooterSectionsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 50% 20% 20%;
  grid-gap: 5%;

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
    grid-gap: 0;
  }
`

export const Form = styled.form`
  width: 400px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    margin-bottom: 30px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`

export const FormLabel = styled.label`
  font-weight: 400;
  color: #333;
  font-size: 20px;
  margin: 0 0 20px 0;

  @media (max-width: 1200px) {
    font-size: 18px;
  }
`

export const FormLabelSearch = styled(FormLabel)`
  margin-top: 20px;
`

export const FormSubLabel = styled.span`
  font-weight: 300;
  color: #9b9b9b;
  font-size: 14px;
  margin: 0 0 20px 0;
`

export const FormWrapper = styled.div`
  width: 100%;
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 30px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  @media (max-width: 1400px) and (max-height: 900px) {
    height: 40px;
  }
`

export const FormInput = styled.input`
  width: calc(100% - 80px);
  height: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  font-weight: 300;
  color: black;
  font-size: 16px;
  border: none;
  background: none;
  border-radius: 30px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(94, 158, 214, 1);
  }
  &::placeholder {
    color: #bbbbbb;
  }
  @media (max-width: 1400px) and (max-height: 900px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    width: calc(100% - 60px);
    padding: 0 20px;
  }
`

export const FormButton = styled.button`
  width: 125px;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: #ffcb00;
  border: none;
  border-radius: 30px;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  box-shadow: 0px 0px 15px 0px rgba(255, 203, 0, 0.5);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(94, 158, 214, 1);
  }
  @media (max-width: 1400px) and (max-height: 900px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    width: 100px;
  }
`

export const Message = styled.div`
  font-weight: 300;
  font-size: 12px;
  margin: 10px 0 0 0;
  color: #333;
  opacity: ${props => (props.emailmessage === "empty" ? "0" : "1")};
`

export const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    margin-bottom: 50px;
  }
`

export const SectionTitle = styled.p`
  font-weight: 400;
  color: #333;
  font-size: 20px;
  margin: 0 0 20px 0;

  @media (max-width: 1200px) {
    font-size: 18px;
  }
`

export const SectionLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const LogoWrapperLink = styled.a`
  text-decoration: none;
  margin-right: 20px;
`

export const SocialLogo = styled.img`
  width: 30px;
`

export const PageLink = styled.div`
  text-decoration: none;
  color: #333;
  font-size: 16px;
  margin: 0 0 5px 0;
  font-weight: 300;
  &:hover {
    cursor: pointer;
  }
`

export const ExternalLink = styled.a`
  text-decoration: none;
  color: #333;
  font-size: 16px;
  margin: 0 0 5px 0;
  font-weight: 300;
`

export const SearchIcon = styled.img`
  width: 20px;
`

export const SearchButton = styled(FormButton)`
  width: auto;
  padding: 0 2rem 0 2rem;
`