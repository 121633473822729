import styled from "styled-components"
import { SlideTitle } from "../../components/globalStyle"

export const BlogContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
`

export const BlogGrid = styled.div`
  align-self: center;
  justify-self: center;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  margin: 30px 0 0 0;
  box-sizing: border-box;
  padding: 0 60px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 700px) {
    padding: 0 0;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  @media (min-width: 1500px) {
    padding: 0px 150px;
  }

  @media (min-width: 2000px) {
    padding: 0px 300px;
  }
`

export const BlogCard = styled.div`
  height: 550px;
  max-width: 100%;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 700px) {
    flex-direction: row;
    height: fit-content;
    width: fit-content;
    align-items: center;
    margin: 10px;
  }
`

export const Title = styled.h3`
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0 0 0;

  @media (max-width: 700px) {
    margin: 0 0 0 10px;
    font-size: 18px;
  }
  @media (max-width: 650px) {
    font-size: 14px;
  }
`

export const BlogPreviewImage = styled.img`
  width: 100%;
  height: 350px;
  object-fit: cover;
  align-self: center;

  @media (max-width: 700px) {
    width: 45%;
    height: 180px;
    align-self: flex-start;
    justify-self: flex-start;
  }

  @media (max-width: 600px) {
    height: 120px;
  }
`

export const BlogContentPreview = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin: 10px 0 0 0;
  @media (max-width: 700px) {
    display: none;
  }
`

export const PageCounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 40px 0 0 0;
  display: ${props => props.display};
`

export const PageCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
`

export const FeaturedContainer = styled.div`
  width: 100%;
  background-color: #333333;
  display: flex;
  flex-direction: column;
  border-bottom: 4px solid #ffcb00;
  box-sizing: border-box;
  padding: 0 60px;

  @media (max-width: 700px) {
    padding: 0 20px;
  }

  @media (min-width: 1500px) {
    padding: 0px 150px;
  }

  @media (min-width: 2000px) {
    padding: 0px 300px;
  }
`

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  margin: 200px 0 0 0;
  @media (max-width: 750px) {
    margin: 0 0 50px 0;
  }
`

export const PageTitle = styled(SlideTitle)`
  position: relative;
  color: white;
  font-size: 80px;
  width: 60%;

  @media (max-width: 700px) {
    font-size: 40px;
    width: 100%;
    margin-top: 200px;
  }
`

export const BlogDescription = styled.p`
  max-width: 800px;
  color: white;
  font-size: 18px;
  font-weight: 300;
  width: 90%;
  margin: 30px 0 50px 0;
  @media (max-width: 700px) {
    display: none;
  }
`

export const PageButton = styled.button`
  border: none;
  background-color: #fafafa;
  margin: 0 5px 0 5px;
`

export const BlogLink = styled.div`
  :hover {
    cursor: pointer;
  }
`

export const BlogPreviewText = styled.div`
  flex-direction: row;
`;

export const BlogContentPreviewMobile = styled.p`
  margin: 10px;
  font-size: 14px;
  @media (min-width: 701px) {
    display: none;
  }
  @media (max-width: 400px) {
    display: none;
  }
`;