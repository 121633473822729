import React from "react"
import { PrivacyPolicyContainer, PrivacyPolicyTitle, Body, Markdown } from './privacyPolicyStyle'
import { gql, useQuery } from '@apollo/client'
import ErrorSection from "../../components/Error/error";

const GET_POLICY = gql`
query GetPolicy {
  pages(where: {title: "Privacy Policy"}) {
    nodes {
      privacyPolicy {
        title
        content
      }
    }
  }
}
`;

function PrivacyPolicyPage() {
  const { loading, error, data } = useQuery(GET_POLICY);
  return (
    <>
      {!loading && !error ?
        <PrivacyPolicyContainer>
          <PrivacyPolicyTitle dangerouslySetInnerHTML={{__html: data.pages.nodes[0].privacyPolicy.title}}/>
          <Body dangerouslySetInnerHTML={{__html: data.pages.nodes[0].privacyPolicy.content}}/>
        </PrivacyPolicyContainer>
        :
        <>
          {error ?
            <ErrorSection/>
            :
            <></>
          }
        </>
      }
    </>
  )
}

export default PrivacyPolicyPage
